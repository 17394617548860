import GenericApi from "@/api/genericApi";
import {
  CompanyUsersQueryKey,
  PermissionUserQueryKey,
  UsersPendingQueryKey,
} from "@/config/constants/QueryKeys";
import { useSnackBarAlert } from "@/hooks/useSnackbar";
import { ApiResponse, ApiError, ApiData } from "@/types/Api";
import { Company, CompanyUser } from "@/types/Company";
import { ValidationErrors } from "@/types/ValidationError";
import CustomLogger from "@/utils/CustomLogger";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Fragment, useEffect, useState } from "react";
import AdaptiveModal from "../AdaptiveModal";
import { CustomModalPosition } from "@/types/Modal";
import { PermissionName } from "@/types/Permissions";
import { mapPermissionToNameAndIcon } from "@/utils/utils";
import { Button } from "flowbite-react";
import React from "react";
import { UserPermissions } from "@/types/UserPermissions";

interface CompanyUserProps {
  user: CompanyUser;
  companies: Company[] | null;
}

interface PermissionItem {
  user?: number;
  company: number;
  read_reports: boolean;
  read_invoicing: boolean;
  edit_invoicing: boolean;
  edit_accounting_and_payroll: boolean;
  company_id?: number
}

interface Permissions {
  [companyId: string]: {
    read_reports: boolean;
    edit_accounting_and_payroll: boolean;
    read_invoicing: boolean;
    edit_invoicing: boolean;
  };
}

const CompanyUserItem: React.FC<CompanyUserProps> = ({ user, companies }) => {
  const queryClient = useQueryClient();
  const { showSnackBar } = useSnackBarAlert();

  const getFullName = () => {
    return `${user.first_name} ${user.last_name}`;
  };

   


  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const mapPermissionsArrayToObject = (
    permissionsArray: PermissionItem[] | null
  ): Permissions => {
    if (!permissionsArray) return {};

    return permissionsArray.reduce((acc, item) => {
      acc[item.company] = {
        read_reports: item.read_reports,
        edit_accounting_and_payroll: item.edit_accounting_and_payroll,
        read_invoicing: item.read_invoicing,
        edit_invoicing: item.edit_invoicing,
      };
      return acc;
    }, {} as Permissions);
  };

  const mapPermissionsObjectToArray = (permissions: Permissions): PermissionItem[] => {
    return Object.entries(permissions).map(([companyId, perms]) => ({
      company_id: Number(companyId),
      company: Number(companyId),
      edit_accounting_and_payroll: perms?.edit_accounting_and_payroll ?? false,
      edit_invoicing: perms?.edit_invoicing ?? false,
      read_invoicing: perms?.read_invoicing ?? false,
      read_reports: perms?.read_reports ?? false
    }));
  };

  const fullName = getFullName();

  const [visibleTooltipId, setVisibleTooltipId] = useState({
    companyId: 0,
    id: 0,
  });

  const route = `/permissions/${user?.id}`;

  const handleMouseEnter = (companyId: number, id: number) =>
    setVisibleTooltipId({ companyId, id });
  const handleMouseLeave = () => setVisibleTooltipId({ companyId: 0, id: 0 });

  const editUserMutation = useMutation<
    ApiResponse<PermissionItem[]>,
    ApiError<ValidationErrors>,
    PermissionItem[]
  >({
    mutationFn: (data) => GenericApi.put<PermissionItem[]>(route, data as unknown as ApiData[]),
    onSuccess: async ({ data }) => {
      CustomLogger.log(data);
      queryClient.invalidateQueries({ queryKey: [CompanyUsersQueryKey] });
      queryClient.invalidateQueries({ queryKey: [PermissionUserQueryKey, user?.id] });
      setIsModalOpen(false)
      showSnackBar(
        "Permissions changed",
        "success",
        { vertical: "top", horizontal: "right" },
        3000
      );
    },
    onError: async ({ error }) => {
      CustomLogger.error(error);
      setIsModalOpen(false)
      showSnackBar(
        "Error editing User",
        "error",
        { vertical: "top", horizontal: "right" },
        3000
      );
    },
  });

  console.log(editUserMutation);

  const deleteUserMutation = useMutation<
    ApiResponse<{ message: string }>,
    ApiError<ValidationErrors>,
    { id: number }
  >({
    mutationFn: (data) =>
      GenericApi.delete<{ message: string }>(`/users/${data.id}`),
    onSuccess: async ({ data }) => {
      CustomLogger.log(data);
      queryClient.invalidateQueries({ queryKey: [CompanyUsersQueryKey] });
      showSnackBar(
        "User deleted",
        "success",
        { vertical: "top", horizontal: "right" },
        3000
      );
    },
    onError: async ({ error }) => {
      CustomLogger.error(error);
      showSnackBar(
        "Error deleting User",
        "error",
        { vertical: "top", horizontal: "right" },
        3000
      );
    },
  });



  const deleteInvitationMutation = useMutation<
    ApiResponse<{ message: string }>,
    ApiError<ValidationErrors>,
    { id: number }
  >({
    mutationFn: (data) =>
      GenericApi.delete<{ message: string }>(`/invites/${data.id}`),
    onSuccess: async ({ data }) => {
      CustomLogger.log(data);
      queryClient.invalidateQueries({ queryKey: [UsersPendingQueryKey] });
      showSnackBar(
        "User deleted",
        "success",
        { vertical: "top", horizontal: "right" },
        3000
      );
    },
    onError: async ({ error }) => {
      CustomLogger.error(error);
      showSnackBar(
        "Error deleting User",
        "error",
        { vertical: "top", horizontal: "right" },
        3000
      );
    },
  });




  const toggleDeleteModal = (userId: number) => {
    console.log(user, "userID");
    deleteUserMutation.mutate({ id: userId });
  };


  const deleteInvitation = (invitationId: number) => {
    deleteInvitationMutation.mutate({id: invitationId})
  }



  const { data: currentPermissions, isSuccess } = useQuery<
    ApiResponse<UserPermissions[]>
  >({
    queryKey: [PermissionUserQueryKey, user?.id],
    queryFn: () => GenericApi.get<UserPermissions[]>(route),
    enabled: !!user?.id,
  });

  console.log({
    [`currentPerms${user?.id}`]: currentPermissions,
  });

  console.log(currentPermissions?.data, 'currentPermissosnssdasdas')


  const [permissions, setPermissions] = useState<Permissions>({});

  useEffect(() => {
    setPermissions(
      mapPermissionsArrayToObject(currentPermissions?.data ?? null)
    );
  }, [isSuccess, currentPermissions?.data]);

  const handlePermissionChange = (
    companyId: string,
    permissionName: PermissionName
  ) => {
    setPermissions((prevPermissions) => ({
      ...prevPermissions,
      [companyId]: {
        ...prevPermissions[companyId],
        [permissionName]: !prevPermissions[companyId]?.[permissionName],
      },
    }));
  };

  console.log({
    PERMS: permissions,
  });

  const handleSubmit = () => {
    const data = mapPermissionsObjectToArray(permissions)
    editUserMutation.mutate(data)
  }

  return (
    <Fragment>
      <tr className="border-b dark:border-gray-700 hover:bg-gray-100 dark:hover:bg-gray-700">
        <td className="px-4 py-3">{fullName}</td>
        <td className="px-4 py-3">{user.email}</td>
        <td className="px-4 py-3">{user.is_active ? "Active" : "Pending"}</td>
        <td className="px-4 py-3 text-right">
          {user?.is_active ? (
            <Fragment>
              <button
                onClick={() => toggleModal()}
                className="font-medium text-green-600 dark:text-green-500 hover:underline"
              >
                Edit
              </button>{" "}
              |{" "}
              <button
                onClick={() => toggleDeleteModal(user.id)}
                className="font-medium text-red-600 dark:text-red-500 hover:underline"
              >
                Delete
              </button>
            </Fragment>
          ) : (
            <button
                onClick={() => deleteInvitation(user.id)}
                className="font-medium text-red-600 dark:text-red-500 hover:underline"
              >
                Delete
              </button>
          )}
        </td>
      </tr>
      <AdaptiveModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        position={CustomModalPosition.Center}
        title={<p className="dark:text-white">Edit user</p>}
      >
        <div className="space-y-2">
          <p className="dark:text-white">Permissions</p>
          <div className="flex flex-col gap-6 p-6 bg-gray-50 dark:bg-gray-800 rounded-lg shadow-md">
            {companies?.map((company) => (
              <div
                key={company.id}
                className="bg-white flex justify-between items-center gap-4 dark:bg-gray-700 rounded-lg p-4 shadow-md hover:shadow-lg transition-shadow duration-300"
              >
                <div className="flex gap-3 items-center">
                  <p className="text-lg font-semibold dark:text-white">
                    {company.name}
                  </p>
                </div>

                <div className="flex gap-2">
                  {Object.values(PermissionName).map((permissionName) => {
                    const { name, icon, id } =
                      mapPermissionToNameAndIcon(permissionName);
                    const isActive = permissions[company.id]?.[permissionName];

                    return (
                      <div key={id} className="w-14 relative inline-block">
                        <Button
                          onMouseEnter={() => handleMouseEnter(company.id, id)}
                          onMouseLeave={handleMouseLeave}
                          onClick={() =>
                            handlePermissionChange(
                              company.id.toString(),
                              permissionName
                            )
                          }
                          className={`ms-3 w-full mt-2 rounded-lg transition duration-300 ${
                            isActive
                              ? "bg-green-200 text-white border border-green-500 hover:bg-green-600"
                              : "bg-gray-200 text-gray-800 border border-gray-300 hover:bg-gray-300"
                          }`}
                          color={isActive ? "green" : "gray"}
                        >
                          {icon}
                        </Button>

                        {visibleTooltipId.id === id &&
                        visibleTooltipId.companyId === company.id ? (
                          <div
                            className="absolute bottom-[3.5rem] left-1 z-10 px-3 py-2 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-100 tooltip dark:bg-gray-700"
                            role="tooltip"
                          >
                            {name}
                            <div className="tooltip-arrow"></div>
                          </div>
                        ) : null}
                      </div>
                    );
                  })}
                </div>
              </div>
            ))}
          </div>
          <div className="flex justify-center w-full">
            <Button onClick={handleSubmit} type="submit" color="blue" className="w-full">
              {editUserMutation.isPending ? (
                <svg
                  className="w-5 h-5 mr-3 border-r-2 border-white rounded-full animate-spin"
                  viewBox="0 0 24 24"
                ></svg>
              ) : (
                "Save"
              )}
            </Button>
          </div>
        </div>
      </AdaptiveModal>
    </Fragment>
  );
};

export default CompanyUserItem;
