import { Currency } from "@/types/Currency";
import { FormFieldType } from "@/types/FormFieldsType";
import {
  formatCategoryToSelectInput,
  formatContactToSelectInput, formatCountryFromCurrencyToSelectInput,
  formatCurrencyToSelectInput,
  formatObjectToSelectInput,
} from "./utils";
import { Company } from "@/types/Company";
import { User } from "@/types/User";
import { ItemType } from "@/types/Items";
import { Account } from "@/types/Banking/Accounts";
import { ReconciliationType } from "@/types/Banking/Reconciliation";
import { TransactionType } from "@/types/Banking/Transactions";
import { Transfer } from "@/types/Banking/Transfer";
import { Category } from "@/types/Settings/Categories";
import { Contact } from "@/types/Settings/Contacts";
import { DefaultSetting } from "@/types/Settings/Defaults";
import { Department } from "@/types/Settings/Departments";
import { ExchangeRate } from "@/types/Settings/ExchangeRates";
import { Tax } from "@/types/Settings/Taxes";
import { DoubleEntryAccountView } from "@/types/DoubleEntry";
import { EmployeeType } from "@/types/Hr/Employee";
import { DocumentType } from "@/types/Document/Document";
import { Page } from "@/types/Page";
import { DoubleEntryTax } from "@/types/DoubleEntryAccountTax";
import { SalaryType } from "@/types/Salary";

export const subcategoryOptions: Record<
  string,
  { label: string; value: string }[]
> = {
  "Fixed Assets": [
    {
      label: "Properties & Other Tangible",
      value: "Properties & Other Tangible",
    },
    { label: "Intangible Assets", value: "Intangible Assets" },
  ],
  "Current Assets": [
    { label: "Inventory", value: "Inventory" },
    { label: "Trade receivables", value: "Trade receivables" },
    { label: "Cash at bank and in hand", value: "Cash at bank and in hand" },
  ],
  "Equity": [
    {
      label: 'Equity', value: 'Equity'
    }
  ],
  "Long Term Liabilities": [{ label: "Loans", value: "Loans" }],
  "Current Liabilities": [
    { label: "Accrued expenses", value: "Accrued expenses" },
    { label: "Client advancements", value: "Client advancements" },
    { label: "Other Creditors/Suppliers", value: "Other Creditors/Suppliers" },
    { label: "Short term borrowing", value: "Short term borrowing" },
  ],
  "Income": [
    {
      label: 'Income', value: 'Income'
    }
  ],
  "Expenses": [
    {
      label: 'Expenses', value: 'Expenses'
    }
  ],
};

const generateRegisterInputs = (currencies: Currency[] | null) => {
  if (!currencies) {
    return [];
  }

  return [
    {
      type: FormFieldType.TEXT,
      placeholder: "Email",
      name: "email",
      required: true,
      valueAsNumber: false,
      label: "Email",
    },
    {
      type: FormFieldType.PASSWORD,
      placeholder: "Password",
      name: "password",
      required: true,
      valueAsNumber: false,
      label: "Password",
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Company Name",
      name: "company_name",
      required: true,
      valueAsNumber: false,
      label: "Company Name",
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Address",
      name: "address",
      required: true,
      valueAsNumber: false,
      label: "Address",
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "VAT Number",
      name: "vat_no_tic",
      required: true,
      valueAsNumber: false,
      label: "VAT Number",
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "HE Number",
      name: "he_number",
      required: true,
      valueAsNumber: false,
      label: "HE Number",
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Tax Jurisdiction",
      name: "tax_jurisdiction",
      required: true,
      valueAsNumber: false,
      label: "Tax Jurisdiction",
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Engagement Type",
      name: "engagement_type",
      required: true,
      valueAsNumber: false,
      label: "Engagement Type",
    },
    {
      type: FormFieldType.SELECT,
      placeholder: "Currency",
      name: "currency",
      required: true,
      valueAsNumber: false,
      label: "Currency",
      defaultValue: currencies[0].name,
      options: formatObjectToSelectInput(currencies),
    },
    {
      type: FormFieldType.CHECKBOX,
      placeholder: "MultiCurrency",
      name: "multi_currency",
      required: false,
      valueAsNumber: false,
      label: "MultiCurrency",
      defaultValue: false,
    },
  ];
};

const generateProfileInputs = (
  user: User | null,
  currencies: Currency[] | null
) => {
  if (!user || !currencies) return [];

  if (!user?.is_organization) {
    return [
      {
        type: FormFieldType.TEXT,
        placeholder: "First Name",
        name: "first_name",
        required: true,
        valueAsNumber: false,
        label: "First Name",
        defaultValue: user?.first_name,
      },
      {
        type: FormFieldType.TEXT,
        placeholder: "Last Name",
        name: "last_name",
        required: true,
        valueAsNumber: false,
        label: "Last Name",
        defaultValue: user?.last_name,
      },
      {
        type: FormFieldType.TEXT,
        placeholder: "Mobile",
        name: "mobile",
        required: true,
        valueAsNumber: false,
        label: "Mobile",
        defaultValue: user?.mobile,
      },
    ];
  }

  return generateUpdateUserOrganizationInputs(user, currencies);
};

const generateChangePasswordInputs = () => {
  return [
    {
      type: FormFieldType.PASSWORD,
      placeholder: "Old Password",
      name: "old_password",
      required: true,
      valueAsNumber: false,
      label: "Old Password",
    },
    {
      type: FormFieldType.PASSWORD,
      placeholder: "New Password",
      name: "new_password",
      required: true,
      valueAsNumber: false,
      label: "New Password",
    },
    {
      type: FormFieldType.PASSWORD,
      placeholder: "Confirm Password",
      name: "confirm_password",
      required: true,
      valueAsNumber: false,
      label: "Confirm Password",
    },
  ];
};

const generateResetPasswordInputs = () => {
  return [
    {
      type: FormFieldType.PASSWORD,
      placeholder: "New Password",
      name: "new_password",
      required: true,
      valueAsNumber: false,
      label: "New Password",
    },
    {
      type: FormFieldType.PASSWORD,
      placeholder: "Confirm Password",
      name: "confirm_password",
      required: true,
      valueAsNumber: false,
      label: "Confirm Password",
    },
  ];
};

export const generateChangeEmailInputs = () => {
  return [
    {
      type: FormFieldType.EMAIL,
      placeholder: "New Email",
      name: "new_email",
      required: true,
      valueAsNumber: false,
      label: "New Email",
      autocomplete: "off",
    },
    {
      type: FormFieldType.EMAIL,
      placeholder: "Confirm Email",
      name: "confirm_email",
      required: true,
      valueAsNumber: false,
      label: "Confirm Email",
      autocomplete: "off",
    },
    {
      type: FormFieldType.PASSWORD,
      placeholder: "Password",
      name: "password",
      required: true,
      valueAsNumber: false,
      label: "Password",
      autocomplete: "off",
    },
  ];
};

export const generateCompanyInputs = (currecies: Currency[] | null) => {
  if (!currecies) {
    return [];
  }

  //TODO DEFAULT COUNTRY IS REQUIRED
  const currencyOptions = formatObjectToSelectInput(currecies);
  const countries = formatCountryFromCurrencyToSelectInput(currecies);

  return [
    {
      type: FormFieldType.TEXT,
      placeholder: "Company Name",
      name: "name",
      required: true,
      valueAsNumber: false,
      label: "Company Name",
    },
    {
      type: FormFieldType.FILE,
      placeholder: "Logo",
      name: "logo",
      required: false,
      valueAsNumber: false,
      label: "Logo",
    },
    {
      type: FormFieldType.EMAIL,
      placeholder: "Company Email",
      name: "email",
      required: true,
      valueAsNumber: false,
      label: "Company Email",
    },
    {
      type: FormFieldType.SELECT,
      placeholder: "Currency",
      name: "currency",
      required: true,
      valueAsNumber: false,
      label: "Currency",
      options: currencyOptions,
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Contact Person",
      name: "contact_person",
      required: true,
      valueAsNumber: false,
      label: "Contact Person",
    },
    {
      type: FormFieldType.SELECT,
      placeholder: "Country",
      name: "country",
      required: true,
      valueAsNumber: false,
      label: "Country",
      defaultValue: countries[0].value,
      readonly: true,
      options: countries,
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Address",
      name: "address",
      required: true,
      valueAsNumber: false,
      label: "Address",
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Phone Number",
      name: "phone",
      required: true,
      valueAsNumber: false,
      label: "Phone Number",
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Tax Number",
      name: "tax_number",
      required: true,
      valueAsNumber: false,
      label: "Tax Number",
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "City",
      name: "city",
      required: true,
      valueAsNumber: false,
      label: "City",
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "State",
      name: "state",
      required: true,
      valueAsNumber: false,
      label: "State",
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Zip Code",
      name: "zip_code",
      required: true,
      valueAsNumber: false,
      label: "Zip Code",
    },
    {
      type: FormFieldType.CHECKBOX,
      placeholder: "MultiCurrency",
      name: "multicurrency",
      required: false,
      valueAsNumber: false,
      label: "Multicurrency",
    },
  ];
};

const generateCompanyUpdateInputs = (
  company: Company | null,
  currecies: Currency[] | null
) => {
  if (!currecies || !company) {
    return [];
  }

  const currencyOptions = formatObjectToSelectInput(currecies);
  const countries = formatCountryFromCurrencyToSelectInput(currecies);

  return [
    {
      type: FormFieldType.TEXT,
      placeholder: "Company Name",
      name: "name",
      required: true,
      defaultValue: company.name,
      valueAsNumber: false,
      label: "Company Name",
    },
    {
      type: FormFieldType.FILE,
      placeholder: "Logo",
      name: "logo",
      required: false,
      valueAsNumber: false,
      label: "Logo",
      // defaultValue:company.logo
    },
    {
      type: FormFieldType.EMAIL,
      placeholder: "Company Email",
      name: "email",
      required: true,
      defaultValue: company.email,
      valueAsNumber: false,
      label: "Company Email",
    },
    {
      type: FormFieldType.SELECT,
      placeholder: "Currency",
      name: "currency",
      required: true,
      defaultValue: company.currency,
      valueAsNumber: false,
      label: "Currency",
      options: currencyOptions,
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Contact Person",
      name: "contact_person",
      required: true,
      valueAsNumber: false,
      label: "Contact Person",
      defaultValue: company.contact_person,
    },
    {
      type: FormFieldType.SELECT,
      placeholder: "Country",
      name: "country",
      required: true,
      valueAsNumber: false,
      label: "Country",
      defaultValue: company.country,
      readonly: true,
      options: countries,
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Address",
      name: "address",
      required: true,
      defaultValue: company.address,
      valueAsNumber: false,
      label: "Address",
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Phone Number",
      name: "phone",
      required: true,
      defaultValue: company.phone,
      valueAsNumber: false,
      label: "Phone Number",
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Tax Number",
      name: "tax_number",
      required: true,
      defaultValue: company.tax_number,
      valueAsNumber: false,
      label: "Tax Number",
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "City",
      name: "city",
      required: true,
      defaultValue: company.city,
      valueAsNumber: false,
      label: "City",
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "State",
      name: "state",
      required: true,
      defaultValue: company.state,
      valueAsNumber: false,
      label: "State",
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Zip Code",
      name: "zip_code",
      required: true,
      defaultValue: company.zip_code,
      valueAsNumber: false,
      label: "Zip Code",
    },
    {
      type: FormFieldType.CHECKBOX,
      placeholder: "MultiCurrency",
      name: "multicurrency",
      required: false,
      valueAsNumber: false,
      label: "Multicurrency",
      defaultValue: company.multicurrency,
    },
  ];
};

const generateUpdateUserOrganizationInputs = (
  user: User,
  currencies: Currency[]
) => {
  return [
    {
      type: FormFieldType.TEXT,
      placeholder: "Company Name",
      name: "company_name",
      required: true,
      valueAsNumber: false,
      label: "Company Name",
      defaultValue: user?.company_name ?? "",
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Address",
      name: "address",
      required: true,
      valueAsNumber: false,
      label: "Address",
      defaultValue: user?.address ?? "",
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "VAT Number",
      name: "vat_no_tic",
      required: true,
      valueAsNumber: false,
      label: "VAT Number",
      defaultValue: user?.vat_no_tic ?? "",
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "HE Number",
      name: "he_number",
      required: true,
      valueAsNumber: false,
      label: "HE Number",
      defaultValue: user?.he_number ?? "",
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Tax Jurisdiction",
      name: "tax_jurisdiction",
      required: true,
      valueAsNumber: false,
      label: "Tax Jurisdiction",
      defaultValue: user?.tax_jurisdiction ?? "",
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Engagement Type",
      name: "engagement_type",
      required: true,
      valueAsNumber: false,
      label: "Engagement Type",
      defaultValue: user?.engagement_type ?? "",
    },
    {
      type: FormFieldType.SELECT,
      placeholder: "Currency",
      name: "currency",
      required: true,
      valueAsNumber: false,
      label: "Currency",
      defaultValue: user?.currency ?? "",
      options: formatObjectToSelectInput(currencies),
    },
    {
      type: FormFieldType.CHECKBOX,
      placeholder: "Multi Currency",
      name: "multi_currency",
      required: false,
      valueAsNumber: false,
      label: "MultiCurrency",
      defaultValue: user.multi_currency ?? false,
    },
  ];
};

const generateCreateUserInputs = () => {
  return [
    {
      type: FormFieldType.TEXT,
      placeholder: "First Name",
      name: "first_name",
      required: true,
      valueAsNumber: false,
      label: "First Name",
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Last Name",
      name: "last_name",
      required: true,
      valueAsNumber: false,
      label: "Last Name",
    },
    {
      type: FormFieldType.EMAIL,
      placeholder: "Email",
      name: "email",
      required: true,
      valueAsNumber: false,
      label: "Email",
    },
    {
      type: FormFieldType.PASSWORD,
      placeholder: "Password",
      name: "password",
      required: true,
      valueAsNumber: false,
      label: "Password",
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Mobile",
      name: "mobile",
      required: true,
      valueAsNumber: false,
      label: "Mobile",
    },
  ];
};

const generateItemInputs = () => {
  return [
    {
      type: FormFieldType.SELECT,
      placeholder: "Type",
      name: "type",
      required: true,
      valueAsNumber: false,
      label: "Type",
      options: [
        {
          label: "Product",
          value: "product",
        },
        {
          label: "Service",
          value: "service",
        },
      ],
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Item Name",
      name: "name",
      required: true,
      valueAsNumber: false,
      label: "Name",
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Description",
      name: "description",
      required: false,
      valueAsNumber: false,
      label: "Description",
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Sale Price",
      name: "sale_price",
      required: true,
      valueAsNumber: false,
      label: "Sale Price",
    },
  ];
};

const generateItemUpdateInputs = (items: ItemType | null) => {
  if (!items) {
    return [];
  }

  return [
    {
      type: FormFieldType.SELECT,
      placeholder: "Type",
      name: "type",
      required: true,
      valueAsNumber: false,
      label: "Type",
      defaultValue: items.type,
      options: [
        {
          label: "Product",
          value: "product",
        },
        {
          label: "Service",
          value: "service",
        },
      ],
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Item Name",
      name: "name",
      required: true,
      valueAsNumber: false,
      label: "Name",
      defaultValue: items?.name,
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Description",
      name: "description",
      required: false,
      valueAsNumber: false,
      label: "Description",
      defaultValue: items.description,
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Sale Price",
      name: "sale_price",
      required: true,
      valueAsNumber: false,
      label: "Sale Price",
      defaultValue: items.sale_price,
    },
  ];
};

const generateAccountInputs = (currecies: Currency[] | null) => {

  const options = formatCurrencyToSelectInput(currecies)

  return [
    {
      type: FormFieldType.TEXT,
      placeholder: "Account Name",
      name: "name",
      required: true,
      valueAsNumber: false,
      label: "Name",
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Account Number",
      name: "number",
      required: true,
      valueAsNumber: false,
      label: "Account Number",
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "IBAN",
      name: "iban",
      required: true,
      valueAsNumber: false,
      label: "IBAN",
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Swift",
      name: "swift",
      required: true,
      valueAsNumber: false,
      label: "Swift",
    },
    {
      type: FormFieldType.SELECT,
      placeholder: "Currency",
      name: "currency",
      required: true,
      valueAsNumber: false,
      label: "Currency",
      options:options
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Bank Name",
      name: "bank_name",
      required: false,
      valueAsNumber: false,
      label: "Bank Name",
    },
  ];
};

const generateAccountUpdateInputs = (accounts: Account | null, currecies: Currency[] | null) => {
  if (!accounts) {
    return [];
  }
  const options = formatCurrencyToSelectInput(currecies)

  return [
    {
      type: FormFieldType.TEXT,
      placeholder: "Account Name",
      name: "name",
      required: true,
      valueAsNumber: false,
      label: "Name",
      defaultValue: accounts.name,
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Account Number",
      name: "number",
      required: true,
      valueAsNumber: false,
      label: "Account Number",
      defaultValue: accounts.number,
    },
    {
      type: FormFieldType.SELECT,
      placeholder: "Currency",
      name: "currency",
      required: true,
      valueAsNumber: false,
      label: "Currency",
      defaultValue: accounts.currency,
      options:options
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "IBAN",
      name: "iban",
      required: true,
      valueAsNumber: false,
      label: "IBAN",
      defaultValue: accounts.iban,
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Swift",
      name: "swift",
      required: true,
      valueAsNumber: false,
      label: "Swift",
      defaultValue: accounts.swift,
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Bank Name",
      name: "bank_name",
      required: false,
      valueAsNumber: false,
      label: "Bank Name",
      defaultValue: accounts.bank_name,
    },
  ];
};

const generateReconcliationInputs = () => {
 

  return [
    {
      type: FormFieldType.DATE,
      placeholder: "Start Date",
      name: "started_at",
      required: true,
      valueAsNumber: false,
      label: "Started At",
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Account",
      name: "account",
      required: true,
      valueAsNumber: false,
      label: "Account",
    },
    {
      type: FormFieldType.DATE,
      placeholder: "End Date",
      name: "ended_at",
      required: true,
      valueAsNumber: false,
      label: "Ended At",
    },
    {
      type: FormFieldType.NUMBER,
      placeholder: "Opening Balance",
      name: "opening_balance",
      required: true,
      valueAsNumber: true,
      label: "Opening Balance",
    },
    {
      type: FormFieldType.NUMBER,
      placeholder: "Closing Balance",
      name: "closing_balance",
      required: true,
      valueAsNumber: true,
      label: "Closing Balance",
    },
    {
      type: FormFieldType.CHECKBOX,
      placeholder: "",
      name: "reconciled",
      required: false,
      valueAsNumber: false,
      label: "Reconciled",
    },
  ];
};

const generateReconcliationUpdateInputs = (
  reconciliation: ReconciliationType | null,
) => {
  if (!reconciliation) {
    return [];
  }


  return [
    {
      type: FormFieldType.TEXT,
      placeholder: "Account",
      name: "account",
      required: true,
      valueAsNumber: false,
      label: "Account",
      defaultValue: reconciliation.account,
    },
    {
      type: FormFieldType.DATE,
      placeholder: "Start Date",
      name: "started_at",
      required: true,
      valueAsNumber: false,
      label: "Started At",
      defaultValue: reconciliation.started_at,
    },
    {
      type: FormFieldType.DATE,
      placeholder: "End Date",
      name: "ended_at",
      required: true,
      valueAsNumber: false,
      label: "Ended At",
      defaultValue: reconciliation.ended_at,
    },
    {
      type: FormFieldType.NUMBER,
      placeholder: "Opening Balance",
      name: "opening_balance",
      required: true,
      valueAsNumber: true,
      label: "Opening Balance",
      defaultValue: reconciliation.opening_balance,
    },
    {
      type: FormFieldType.NUMBER,
      placeholder: "Closing Balance",
      name: "closing_balance",
      required: true,
      valueAsNumber: true,
      label: "Closing Balance",
      defaultValue: reconciliation.closing_balance,
    },
    {
      type: FormFieldType.CHECKBOX,
      placeholder: "",
      name: "reconciled",
      required: false,
      valueAsNumber: false,
      label: "Reconciled",
      defaultValue: false,
    },
  ];
};

const generateTransactionsInputs = () => {
  
  return [
    {
      type: FormFieldType.TEXT,
      placeholder: "Transaction Type",
      name: "type",
      required: true,
      valueAsNumber: false,
      label: "Type",
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Transaction Number",
      name: "number",
      required: true,
      valueAsNumber: false,
      label: "Number",
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Account",
      name: "account",
      required: true,
      valueAsNumber: false,
      label: "Account",
    },
    {
      type: FormFieldType.DATE,
      placeholder: "Paid At",
      name: "paid_at",
      required: true,
      valueAsNumber: false,
      label: "Paid At",
    },
    {
      type: FormFieldType.NUMBER,
      placeholder: "Amount",
      name: "amount",
      required: true,
      valueAsNumber: true,
      label: "Amount",
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Currency Code",
      name: "currency_code",
      required: true,
      valueAsNumber: false,
      label: "Currency Code",
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Currency Rate",
      name: "currency_rate",
      required: true,
      valueAsNumber: false,
      label: "Currency Rate",
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Document",
      name: "document",
      required: true,
      valueAsNumber: false,
      label: "Document",
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Contact",
      name: "contact",
      required: true,
      valueAsNumber: false,
      label: "Contact",
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Description",
      name: "description",
      required: true,
      valueAsNumber: false,
      label: "Description",
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Category",
      name: "category",
      required: true,
      valueAsNumber: false,
      label: "Category",
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Payment Method",
      name: "payment_method",
      required: true,
      valueAsNumber: false,
      label: "Payment Method",
      options: [
        {
          label: "Cash",
          value: "cash",
        },
        {
          label: "Bank",
          value: "bank",
        },
        {
          label: "Card",
          value: "card",
        },
      ],
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Reference",
      name: "reference",
      required: false,
      valueAsNumber: false,
      label: "Reference",
    },
    {
      type: FormFieldType.CHECKBOX,
      placeholder: "Reconciled",
      name: "reconciled",
      required: false,
      valueAsNumber: false,
      label: "Reconciled",
    },
  ];
};

const generateTransactionsUpdateInputs = (
  transaction: TransactionType | null,
) => {
  if (!transaction) {
    return [];
  }

  

  return [
    {
      type: FormFieldType.TEXT,
      placeholder: "Transaction Type",
      name: "type",
      required: true,
      valueAsNumber: false,
      label: "Type",
      defaultValue: transaction.type,
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Transaction Number",
      name: "number",
      required: true,
      valueAsNumber: false,
      label: "Number",
      defaultValue: transaction.number,
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Account",
      name: "account",
      required: true,
      valueAsNumber: false,
      label: "Account",
      defaultValue: transaction.account,
    },
    {
      type: FormFieldType.DATE,
      placeholder: "Paid At",
      name: "paid_at",
      required: true,
      valueAsNumber: false,
      label: "Paid At",
      defaultValue: transaction.paid_at,
    },
    {
      type: FormFieldType.NUMBER,
      placeholder: "Amount",
      name: "amount",
      required: true,
      valueAsNumber: true,
      label: "Amount",
      defaultValue: transaction.amount,
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Currency Code",
      name: "currency_code",
      required: true,
      valueAsNumber: false,
      label: "Currency Code",
      defaultValue: transaction.currency_code,
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Currency Rate",
      name: "currency_rate",
      required: true,
      valueAsNumber: false,
      label: "Currency Rate",
      defaultValue: transaction.currency_rate,
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Document",
      name: "document",
      required: true,
      valueAsNumber: false,
      label: "Document",
      defaultValue: transaction.document
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Contact",
      name: "contact",
      required: true,
      valueAsNumber: false,
      label: "Contact",
      defaultValue: transaction.contact
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Description",
      name: "description",
      required: true,
      valueAsNumber: false,
      label: "Description",
      defaultValue: transaction.description,
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Category",
      name: "category",
      required: true,
      valueAsNumber: false,
      label: "Category",
      defaultValue: transaction.category
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Payment Method",
      name: "payment_method",
      required: true,
      valueAsNumber: false,
      label: "Payment Method",
      defaultValue: transaction.payment_method,
      options: [
        {
          label: "Cash",
          value: "cash",
        },
        {
          label: "Bank",
          value: "bank",
        },
        {
          label: "Card",
          value: "card",
        },
      ],
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Reference",
      name: "reference",
      required: false,
      valueAsNumber: false,
      label: "Reference",
    },
    {
      type: FormFieldType.CHECKBOX,
      placeholder: "Reconciled",
      name: "reconciled",
      required: false,
      valueAsNumber: false,
      label: "Reconciled",
      defaultValue: transaction.reconciled,
    },
  ];
};

const generateCategoriesUpdatesInputs = (category: Category | null) => {
  if (!category) {
    return [];
  }
  return [
    {
      type: FormFieldType.TEXT,
      placeholder: "Category Name",
      name: "name",
      required: true,
      valueAsNumber: false,
      label: "Name",
      defaultValue: category.name,
    },
    {
      type: FormFieldType.SELECT,
      placeholder: "Category Type",
      name: "type",
      required: true,
      valueAsNumber: false,
      label: "Type",
      options: [
        { label: "Income", value: "Income" },
        { label: "Expense", value: "Expense" },
        { label: "Item", value: "Item" },
        { label: "Other", value: "Other" },
      ],
      defaultValue: category.type,
    },
  ];
};

const generateContactsUpdateInputs = (contact: Contact | null) => {
  if (!contact) {
    return [];
  }

  return [
    {
      type: FormFieldType.SELECT,
      placeholder: "Contact Type",
      name: "type",
      required: true,
      valueAsNumber: false,
      label: "Type",
      defaultValue: contact.type,
      options: [
        { label: "Customer", value: "customer" },
        { label: "Vendor", value: "vendor" },
      ],
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Name",
      name: "name",
      required: true,
      valueAsNumber: false,
      label: "Name",
      defaultValue: contact.name,
    },
    {
      type: FormFieldType.EMAIL,
      placeholder: "Email",
      name: "email",
      required: true,
      valueAsNumber: false,
      label: "Email",
      defaultValue: contact.email,
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Tax Number",
      name: "tax_number",
      required: false,
      valueAsNumber: false,
      label: "Tax Number",
      defaultValue: contact.tax_number,
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Phone",
      name: "phone",
      required: false,
      valueAsNumber: false,
      label: "Phone",
      defaultValue: contact.phone,
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Address",
      name: "address",
      required: false,
      valueAsNumber: false,
      label: "Address",
      defaultValue: contact.address,
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "City",
      name: "city",
      required: false,
      valueAsNumber: false,
      label: "City",
      defaultValue: contact.city,
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "State",
      name: "state",
      required: false,
      valueAsNumber: false,
      label: "State",
      defaultValue: contact.state,
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Zip Code",
      name: "zip_code",
      required: false,
      valueAsNumber: false,
      label: "Zip Code",
      defaultValue: contact.zip_code,
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Country",
      name: "country",
      required: false,
      valueAsNumber: false,
      label: "Country",
      defaultValue: contact.country,
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Website",
      name: "website",
      required: false,
      valueAsNumber: false,
      label: "Website",
      defaultValue: contact.website,
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Currency",
      name: "currency",
      required: true,
      valueAsNumber: false,
      label: "Currency",
      defaultValue: contact.currency,
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Reference",
      name: "reference",
      required: false,
      valueAsNumber: false,
      label: "Reference",
      defaultValue: contact.reference,
    },
  ];
};

const generateDepartmentsUpdateInputs = (department: Department | null) => {
  if (!department) {
    return [];
  }

  return [
    {
      type: FormFieldType.TEXT,
      placeholder: "Department Name",
      name: "name",
      required: true,
      valueAsNumber: false,
      label: "Name",
      defaultValue: department.name,
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Description",
      name: "description",
      required: false,
      valueAsNumber: false,
      label: "Description",
      defaultValue: department.description,
    },
  ];
};

const generateExchangeRatesUpdateInputs = (
  exchangeRate: ExchangeRate | null
) => {
  if (!exchangeRate) {
    return [];
  }

  return [
    {
      type: FormFieldType.TEXT,
      placeholder: "Base Currency",
      name: "base_currency",
      required: true,
      valueAsNumber: false,
      label: "Base Currency",
      defaultValue: exchangeRate.base_currency,
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Quote Currency",
      name: "quote_currency",
      required: true,
      valueAsNumber: false,
      label: "Quote Currency",
      defaultValue: exchangeRate.quote_currency,
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Exchange Rate",
      name: "rate",
      required: true,
      valueAsNumber: false,
      label: "Rate",
      defaultValue: exchangeRate.rate,
    },
    {
      type: FormFieldType.DATE,
      placeholder: "Date",
      name: "date",
      required: true,
      valueAsNumber: false,
      label: "Date",
      defaultValue: exchangeRate.date,
    },
  ];
};

const generateTransferUpdateInputs = (
  transfer: Transfer,
) => {


  return [
    {
      type: FormFieldType.DATE,
      placeholder: "Transferred At",
      name: "transferred_at",
      required: true,
      valueAsNumber: false,
      label: "Transferred At",
      defaultValue: transfer.transferred_at,
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "From Account",
      name: "from_account",
      required: true,
      valueAsNumber: false,
      label: "From Account",
      defaultValue: transfer.from_account,
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "To Account",
      name: "to_account",
      required: true,
      valueAsNumber: false,
      label: "To Account",
      defaultValue: transfer.to_account,
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Description",
      name: "description",
      required: false,
      valueAsNumber: false,
      label: "Description",
      defaultValue: transfer.description,
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Amount",
      name: "amount",
      required: true,
      valueAsNumber: true,
      label: "Amount",
      defaultValue: transfer.amount,
    },
    {
      type: FormFieldType.SELECT,
      placeholder: "Payment Method",
      name: "payment_method",
      required: true,
      valueAsNumber: false,
      label: "Payment Method",
      defaultValue: transfer.payment_method,
      options: [
        { label: "Cash", value: "CASH" },
        { label: "Bank Transfer", value: "BANK" },
        { label: "Credit Card", value: "CREDIT_CARD" },
      ],
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Reference",
      name: "reference",
      required: false,
      valueAsNumber: false,
      label: "Reference",
      defaultValue: transfer.reference,
    },
  ];
};

const generateTaxesUpdateInputs = (tax: Tax | null) => {
  if (!tax) {
    return [];
  }

  return [
    {
      type: FormFieldType.TEXT,
      placeholder: "Tax Name",
      name: "name",
      required: true,
      valueAsNumber: false,
      label: "Name",
      defaultValue: tax.name,
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Tax Rate",
      name: "rate",
      required: true,
      valueAsNumber: false,
      label: "Rate",
      defaultValue: tax.rate,
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Tax Type",
      name: "type",
      required: true,
      valueAsNumber: false,
      label: "Type",
      defaultValue: tax.type,
    },
  ];
};

const generateCategoriesInputs = (category: Category[] | null) => {
  if (!category) {
    return [];
  }
  return [
    {
      type: FormFieldType.TEXT,
      placeholder: "Category Name",
      name: "name",
      required: true,
      valueAsNumber: false,
      label: "Name",
    },
    {
      type: FormFieldType.SELECT,
      placeholder: "Category Type",
      name: "type",
      required: true,
      valueAsNumber: false,
      label: "Type",
      options: [
        { label: "Income", value: "Income" },
        { label: "Expense", value: "Expense" },
        { label: "Item", value: "Item" },
        { label: "Other", value: "Other" },
      ],
    },
  ];
};

const generateContactsInputs = (contact: Contact[] | null) => {
  if (!contact) {
    return [];
  }

  if (!contact) {
    return [];
  }

  return [
    {
      type: FormFieldType.SELECT,
      placeholder: "Contact Type",
      name: "type",
      required: true,
      valueAsNumber: false,
      label: "Type",
      options: [
        { label: "Customer", value: "customer" },
        { label: "Vendor", value: "vendor" },
      ],
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Name",
      name: "name",
      required: true,
      valueAsNumber: false,
      label: "Name",
    },
    {
      type: FormFieldType.EMAIL,
      placeholder: "Email",
      name: "email",
      required: true,
      valueAsNumber: false,
      label: "Email",
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Tax Number",
      name: "tax_number",
      required: false,
      valueAsNumber: false,
      label: "Tax Number",
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Phone",
      name: "phone",
      required: false,
      valueAsNumber: false,
      label: "Phone",
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Address",
      name: "address",
      required: false,
      valueAsNumber: false,
      label: "Address",
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "City",
      name: "city",
      required: false,
      valueAsNumber: false,
      label: "City",
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "State",
      name: "state",
      required: false,
      valueAsNumber: false,
      label: "State",
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Zip Code",
      name: "zip_code",
      required: false,
      valueAsNumber: false,
      label: "Zip Code",
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Country",
      name: "country",
      required: false,
      valueAsNumber: false,
      label: "Country",
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Website",
      name: "website",
      required: false,
      valueAsNumber: false,
      label: "Website",
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Currency",
      name: "currency",
      required: true,
      valueAsNumber: false,
      label: "Currency",
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Reference",
      name: "reference",
      required: false,
      valueAsNumber: false,
      label: "Reference",
    },
  ];
};

const generateDefaultsInputs = (defaultSetting: DefaultSetting[] | null) => {
  if (!defaultSetting) {
    return [];
  }

  return [
    {
      type: FormFieldType.TEXT,
      placeholder: "Department Name",
      name: "name",
      required: true,
      valueAsNumber: false,
      label: "Name",
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Description",
      name: "description",
      required: false,
      valueAsNumber: false,
      label: "Description",
    },
  ];
};

const generateDepartmentsInputs = (department: Department[] | null) => {
  if (!department) {
    return [];
  }

  return [
    {
      type: FormFieldType.TEXT,
      placeholder: "Department Name",
      name: "name",
      required: true,
      valueAsNumber: false,
      label: "Name",
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Description",
      name: "description",
      required: false,
      valueAsNumber: false,
      label: "Description",
    },
  ];
};

const generateExchangeRatesInputs = (exchangeRate: ExchangeRate[] | null) => {
  if (!exchangeRate) {
    return [];
  }

  return [
    {
      type: FormFieldType.TEXT,
      placeholder: "Base Currency",
      name: "base_currency",
      required: true,
      valueAsNumber: false,
      label: "Base Currency",
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Quote Currency",
      name: "quote_currency",
      required: true,
      valueAsNumber: false,
      label: "Quote Currency",
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Exchange Rate",
      name: "rate",
      required: true,
      valueAsNumber: false,
      label: "Rate",
    },
    {
      type: FormFieldType.DATE,
      placeholder: "Date",
      name: "date",
      required: true,
      valueAsNumber: false,
      label: "Date",
    },
  ];
};

const generateTaxesInputs = (tax: Tax[] | null) => {
  if (!tax) {
    return [];
  }

  return [
    {
      type: FormFieldType.TEXT,
      placeholder: "Tax Name",
      name: "name",
      required: true,
      valueAsNumber: false,
      label: "Name",
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Tax Rate",
      name: "rate",
      required: true,
      valueAsNumber: false,
      label: "Rate",
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Tax Type",
      name: "type",
      required: true,
      valueAsNumber: false,
      label: "Type",
    },
  ];
};

const generateSalaryInputs = (salary: SalaryType[] | null) => {
  if (!salary) {
    return [];
  }

  return [
    {
      type: FormFieldType.TEXT,
      placeholder: "First Name",
      name: "first_name",
      required: true,
      valueAsNumber: false,
      label: "First Name",
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Last Name",
      name: "last_name",
      required: true,
      valueAsNumber: false,
      label: "Last Name",
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Gross Salary",
      name: "gross_salary",
      required: true,
      valueAsNumber: false,
      label: "Gross Salary",
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "ID NO.",
      name: "id_no",
      required: true,
      valueAsNumber: false,
      label: "ID NO.",
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Tax ID NO.",
      name: "tax_id_no",
      required: true,
      valueAsNumber: false,
      label: "Tax ID NO.",
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "SI NO.",
      name: "si_no",
      required: true,
      valueAsNumber: false,
      label: "SI NO.",
    },
    {
      type: FormFieldType.NUMBER,
      placeholder: "Hours Per Month",
      name: "hours_per_month",
      required: true,
      valueAsNumber: true,
      label: "Hours Per Month",
    },
    {
      type: FormFieldType.DATE,
      placeholder: "Hired At",
      name: "hired_at",
      required: true,
      valueAsNumber: false,
      label: "Hired At",
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Address",
      name: "address",
      required: true,
      valueAsNumber: false,
      label: "Address",
    },
    {
      type: FormFieldType.CHECKBOX,
      placeholder: "13th Salary",
      name: "salary_13th",
      required: false,
      valueAsNumber: false,
      label: "13th Salary",
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "0",
      name: "holiday_fund",
      required: false,
      valueAsNumber: false,
      label: "Holiday Fund",
    }
  ];
};

const generateSalaryUpdateInputs = (salary: SalaryType | null) => {
  if (!salary) {
    return [];
  }

  return [
    {
      type: FormFieldType.TEXT,
      placeholder: "First Name",
      name: "first_name",
      required: true,
      valueAsNumber: false,
      label: "First Name",
      defaultValue: salary.first_name,
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Last Name",
      name: "last_name",
      required: true,
      valueAsNumber: false,
      label: "Last Name",
      defaultValue: salary.last_name,
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Gross Salary",
      name: "gross_salary",
      required: true,
      valueAsNumber: false,
      label: "Gross Salary",
      defaultValue: salary.gross_salary,
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "ID NO.",
      name: "id_no",
      required: true,
      valueAsNumber: false,
      label: "ID NO.",
      defaultValue: salary.id_no,
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Tax ID NO.",
      name: "tax_id_no",
      required: true,
      valueAsNumber: false,
      label: "Tax ID NO.",
      defaultValue: salary.tax_id_no,
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "SI NO.",
      name: "si_no",
      required: true,
      valueAsNumber: false,
      label: "SI NO.",
      defaultValue: salary.si_no,
    },
    {
      type: FormFieldType.NUMBER,
      placeholder: "Hours Per Month",
      name: "hours_per_month",
      required: true,
      valueAsNumber: true,
      label: "Hours Per Month",
      defaultValue: salary.hours_per_month,
    },
    {
      type: FormFieldType.DATE,
      placeholder: "Hired At",
      name: "hired_at",
      required: true,
      valueAsNumber: false,
      label: "Hired At",
      defaultValue: new Date(salary.hired_at).toISOString().split('T')[0],
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Address",
      name: "address",
      required: true,
      valueAsNumber: false,
      label: "Address",
      defaultValue: salary.address,
    },
    {
      type: FormFieldType.CHECKBOX,
      placeholder: "13th Salary",
      name: "salary_13th",
      required: false,
      valueAsNumber: false,
      label: "13th Salary",
      defaultValue: salary.salary_13th,
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "0",
      name: "holiday_fund",
      required: false,
      valueAsNumber: false,
      label: "Holiday Fund",
      defaultValue: salary.holiday_fund,
    },
  ];
};

const generateDoubleEntryAccountUpdateInputs = (
  doubleEntryAccount: DoubleEntryAccountView | null
) => {
  if (!doubleEntryAccount) {
    return [];
  }

  const getSubcategories = (accountType: string) => {
    if (accountType === "Fixed Assets") {
      return [
        { label: "Properties & Other Tangible", value: "Properties & Other Tangible" },
        { label: "Intangible Assets", value: "Intangible Assets" },
      ];
    } else if (accountType === "Current Assets") {
      return [
        { label: "Inventory", value: "Inventory" },
        { label: "Trade receivables", value: "Trade receivables" },
        { label: "Cash at bank and in hand", value: "Cash at bank and in hand" },
      ];
    } else if (accountType === "Equity") {
      return [];
    } else if (accountType === "Long Term Liabilities") {
      return [{ label: "Loans", value: "Loans" }];
    } else if (accountType === "Current Liabilities") {
      return [
        { label: "Accrued expenses", value: "Accrued expenses" },
        { label: "Client advancements", value: "Client advancements" },
        { label: "Other Creditors/Suppliers", value: "Other Creditors/Suppliers" },
        { label: "Short term borrowing", value: "Short term borrowing" },
      ];
    } else {
      return [];
    }
  };


  return [
    {
      type: FormFieldType.TEXT,
      placeholder: "Name",
      name: "name",
      required: true,
      valueAsNumber: false,
      label: "Name",
      defaultValue: doubleEntryAccount.name,
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Code",
      name: "code",
      required: true,
      valueAsNumber: false,
      label: "Code",
      defaultValue: doubleEntryAccount.code,
    },
    {
      type: FormFieldType.SELECT,
      placeholder: "Type",
      name: "type",
      required: true,
      valueAsNumber: false,
      label: "Type",
      defaultValue: doubleEntryAccount.type,
      options: [
        { label: "Fixed Assets", value: "Fixed Assets" },
        { label: "Current Assets", value: "Current Assets" },
        { label: "Long Term Liabilities", value: "Long Term Liabilities" },
        { label: "Equity", value: "Equity" },
        { label: "Current Liabilities", value: "Current Liabilities" },
        { label: "Income", value:"Income" },
        { label: "Expenses", value:"Expenses" },
        
      ],
    },
    {
      type: FormFieldType.SELECT,
      placeholder: "Subcategory",
      name: "subcategory",
      required: true,
      valueAsNumber: false,
      label: "Subcategory",
      defaultValue: doubleEntryAccount.subcategory,
      options: getSubcategories(doubleEntryAccount.type)
    }
  ];
};

const generateDoubleEntryAccountsInputs = (doubleEntryAccount: DoubleEntryAccountView | null) => {

  console.log(doubleEntryAccount?.type , "DASFAFAFAFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFF")

  
  const getSubcategories = (accountType: string) => {
  if (accountType === "Fixed Assets") {
    return [
      { label: "Properties & Other Tangible", value: "Properties & Other Tangible" },
      { label: "Intangible Assets", value: "Intangible Assets" },
    ];
  } else if (accountType === "Current Assets") {
    return [
      { label: "Inventory", value: "Inventory" },
      { label: "Trade receivables", value: "Trade receivables" },
      { label: "Cash at bank and in hand", value: "Cash at bank and in hand" },
    ];
  } else if (accountType === "Equity") {
    return [];
  } else if (accountType === "Long Term Liabilities") {
    return [{ label: "Loans", value: "Loans" }];
  } else if (accountType === "Current Liabilities") {
    return [
      { label: "Accrued expenses", value: "Accrued expenses" },
      { label: "Client advancements", value: "Client advancements" },
      { label: "Other Creditors/Suppliers", value: "Other Creditors/Suppliers" },
      { label: "Short term borrowing", value: "Short term borrowing" },
    ];
  } else {
    return [];
  }
};


  return [
    {
      type: FormFieldType.TEXT,
      placeholder: "Name",
      name: "name",
      required: true,
      valueAsNumber: false,
      label: "Name",
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Code",
      name: "code",
      required: true,
      valueAsNumber: false,
      label: "Code",
    },
    {
      type: FormFieldType.SELECT,
      placeholder: "Type",
      name: "type",
      required: true,
      valueAsNumber: false,
      label: "Type",
      options: [
        { label: "Fixed Assets", value: "Fixed Assets" },
        { label: "Current Assets", value: "Current Assets" },
        { label: "Long Term Liabilities", value: "Long Term Liabilities" },
        { label: "Equity", value: "Equity" },
        { label: "Current Liabilities", value: "Current Liabilities" },
        { label: "Income", value:"Income" },
        { label: "Expenses", value:"Expenses" },
      ],
    },
    {
      type: FormFieldType.SELECT,
      placeholder: "Subcategory",
      required: true,
      name: "subcategory",
      valueAsNumber: false,
      label: "Subcategory",
      options: getSubcategories(doubleEntryAccount?.type ?? '')
    },
  ];
};

const generateEmployeeInputs = (currency: Currency[] | null) => {

  const currencyOptions = formatCurrencyToSelectInput(currency)

  return [
    {
      type: FormFieldType.TEXT,
      placeholder: "Name",
      name: "name",
      required: true,
      valueAsNumber: false,
      label: "Name",
    },
    {
      type: FormFieldType.EMAIL,
      placeholder: "Email",
      name: "email",
      required: true,
      valueAsNumber: false,
      label: "Email",
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Phone",
      name: "phone",
      required: false,
      valueAsNumber: false,
      label: "Phone",
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Address",
      name: "address",
      required: false,
      valueAsNumber: false,
      label: "Address",
    },
    {
      type: FormFieldType.SELECT,
      placeholder: "Department",
      name: "department",
      required: true,
      valueAsNumber: false,
      label: "Department",
      options: [
        {
          label: "Back office",
          value: "Back office",
        },
        {
          label: "Office",
          value: "Office",
        },
        {
          label: "Production",
          value: "Production",
        },
        {
          label: "Warehouse",
          value: "Warehouse",
        },
        {
          label: "Driver",
          value: "Driver",
        },
        {
          label: "Accountant",
          value: "Accountant",
        },
      ],
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "City",
      name: "city",
      required: false,
      valueAsNumber: false,
      label: "City",
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "State",
      name: "state",
      required: false,
      valueAsNumber: false,
      label: "State",
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Zip Code",
      name: "zip_code",
      required: false,
      valueAsNumber: false,
      label: "Zip Code",
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Country",
      name: "country",
      required: true,
      valueAsNumber: false,
      label: "Country",
    },
    {
      type: FormFieldType.DATE,
      placeholder: "Date of Birth",
      name: "birth_day",
      required: true,
      valueAsNumber: false,
      label: "Date of Birth",
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Gender",
      name: "gender",
      required: true,
      valueAsNumber: false,
      label: "Gender",
    },
    {
      type: FormFieldType.SELECT,
      placeholder: "Salary Type",
      name: "salary_type",
      required: true,
      valueAsNumber: false,
      label: "Salary Type",
      options: [
        { label: "Monthly", value: "monthly" },
        { label: "Yearly", value: "yearly" },
        { label: "Weekly", value: "weekly" },
        { label: "Bi-Weekly", value: "bi-weekly" },
      ],
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Tax Number",
      name: "tax_number",
      required: false,
      valueAsNumber: false,
      label: "Tax Number",
    },
    {
      type: FormFieldType.NUMBER,
      placeholder: "Amount",
      name: "amount",
      required: true,
      valueAsNumber: true,
      label: "Amount",
    },
    {
      type: FormFieldType.SELECT,
      placeholder: "Currency",
      name: "currency",
      required: true,
      valueAsNumber: false,
      label: "Currency",
      options: currencyOptions
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Bank Account Number",
      name: "bank_account_number",
      required: false,
      valueAsNumber: false,
      label: "Bank Account Number",
    },
    {
      type: FormFieldType.DATE,
      placeholder: "Hired At",
      name: "hired_at",
      required: true,
      valueAsNumber: false,
      label: "Hired At",
    },
  ];
};

const generateEmployeeUpdateInputs = (employee: EmployeeType | null, currency: Currency[] | null) => {
  if (!employee) {
    return [];
  }

  const currencyOptions = formatCurrencyToSelectInput(currency)

  return [
    {
      type: FormFieldType.TEXT,
      placeholder: "Name",
      name: "name",
      required: true,
      valueAsNumber: false,
      label: "Name",
      defaultValue: employee.name,
    },
    {
      type: FormFieldType.EMAIL,
      placeholder: "Email",
      name: "email",
      required: true,
      valueAsNumber: false,
      label: "Email",
      defaultValue: employee.email,
    },
    {
      type: FormFieldType.SELECT,
      placeholder: "Department",
      name: "department",
      required: true,
      valueAsNumber: false,
      label: "Department",
      defaultValue: employee.department,
      options: [
        {
          label: "Back office",
          value: "Back office",
        },
        {
          label: "Office",
          value: "Office",
        },
        {
          label: "Production",
          value: "Production",
        },
        {
          label: "Warehouse",
          value: "Warehouse",
        },
        {
          label: "Driver",
          value: "Driver",
        },
        {
          label: "Accountant",
          value: "Accountant",
        },
      ],
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Phone",
      name: "phone",
      required: false,
      valueAsNumber: false,
      label: "Phone",
      defaultValue: employee.phone,
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Address",
      name: "address",
      required: false,
      valueAsNumber: false,
      label: "Address",
      defaultValue: employee.address,
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "City",
      name: "city",
      required: false,
      valueAsNumber: false,
      label: "City",
      defaultValue: employee.city,
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "State",
      name: "state",
      required: false,
      valueAsNumber: false,
      label: "State",
      defaultValue: employee.state,
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Zip Code",
      name: "zip_code",
      required: false,
      valueAsNumber: false,
      label: "Zip Code",
      defaultValue: employee.zip_code,
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Country",
      name: "country",
      required: true,
      valueAsNumber: false,
      label: "Country",
      defaultValue: employee.country,
    },
    {
      type: FormFieldType.DATE,
      placeholder: "Date of Birth",
      name: "birth_day",
      required: true,
      valueAsNumber: false,
      label: "Date of Birth",
      defaultValue: employee.birth_day,
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Gender",
      name: "gender",
      required: true,
      valueAsNumber: false,
      label: "Gender",
      defaultValue: employee.gender,
    },
    {
      type: FormFieldType.SELECT,
      placeholder: "Salary Type",
      name: "salary_type",
      required: true,
      valueAsNumber: false,
      label: "Salary Type",
      options: [
        { label: "Monthly", value: "monthly" },
        { label: "Yearly", value: "yearly" },
        { label: "Weekly", value: "weekly" },
        { label: "Bi-Weekly", value: "bi-weekly" },
      ],
      defaultValue: employee.salary_type,
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Tax Number",
      name: "tax_number",
      required: false,
      valueAsNumber: false,
      label: "Tax Number",
      defaultValue: employee.tax_number,
    },
    {
      type: FormFieldType.NUMBER,
      placeholder: "Amount",
      name: "amount",
      required: true,
      valueAsNumber: true,
      label: "Amount",
      defaultValue: employee.amount,
    },
    {
      type: FormFieldType.SELECT,
      placeholder: "Currency",
      name: "currency",
      required: true,
      valueAsNumber: false,
      label: "Currency",
      defaultValue: employee.currency,
      options: currencyOptions
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Bank Account Number",
      name: "bank_account_number",
      required: false,
      valueAsNumber: false,
      label: "Bank Account Number",
      defaultValue: employee.bank_account_number,
    },
    {
      type: FormFieldType.DATE,
      placeholder: "Hired At",
      name: "hired_at",
      required: true,
      valueAsNumber: false,
      label: "Hired At",
      defaultValue: employee.hired_at,
    },
  ];
};

const generateTransfersInputs = (
) => {

  return [
    {
      type: FormFieldType.DATE,
      placeholder: "Transferred At",
      name: "transferred_at",
      required: true,
      valueAsNumber: false,
      label: "Transferred At",
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "From Account",
      name: "from_account",
      required: true,
      valueAsNumber: false,
      label: "From Account",
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "To Account",
      name: "to_account",
      required: true,
      valueAsNumber: false,
      label: "To Account",
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Description",
      name: "description",
      required: false,
      valueAsNumber: false,
      label: "Description",
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Amount",
      name: "amount",
      required: true,
      valueAsNumber: true,
      label: "Amount",
    },
    {
      type: FormFieldType.SELECT,
      placeholder: "Payment Method",
      name: "payment_method",
      required: true,
      valueAsNumber: false,
      label: "Payment Method",
      options: [
        { label: "Cash", value: "CASH" },
        { label: "Bank Transfer", value: "BANK" },
        { label: "Credit Card", value: "CREDIT_CARD" },
      ],
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Reference",
      name: "reference",
      required: false,
      valueAsNumber: false,
      label: "Reference",
    },
  ];
};

const generateTransfersUpdateInputs = (transfer: Transfer | null) => {
  if (!transfer) {
    return [];
  }
  return [
    {
      type: FormFieldType.NUMBER,
      placeholder: "Amount",
      name: "amount",
      required: true,
      valueAsNumber: true,
      label: "Amount",
      defaultValue: transfer.amount,
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Reference",
      name: "reference",
      required: false,
      valueAsNumber: false,
      label: "Reference",
      defaultValue: transfer.reference,
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Description",
      name: "description",
      required: false,
      valueAsNumber: false,
      label: "Description",
      defaultValue: transfer.description,
    },
    {
      type: FormFieldType.DATE,
      placeholder: "Transfer Date",
      name: "transferred_at",
      required: true,
      valueAsNumber: false,
      label: "Transfer At",
      defaultValue: transfer.transferred_at,
    },
    {
      type: FormFieldType.SELECT,
      placeholder: "Payment Method",
      name: "payment_method",
      required: true,
      valueAsNumber: false,
      label: "Payment Method",
      options: [
        { label: "Cash", value: "CASH" },
        { label: "Bank Transfer", value: "BANK" },
        { label: "Credit Card", value: "CREDIT_CARD" },
      ],
      defaultValue: transfer.payment_method,
    },
  ];
};

const generateDocumentsInputs = (
  categories: Category[] | null,
  contacts: Contact[] | null,
  currencies: Currency[] | null
) => {
  const categoriesOptions = formatCategoryToSelectInput(categories);
  const contactOptions = formatContactToSelectInput(contacts);
  const currencyOptions = formatCurrencyToSelectInput(currencies);

  return [
    {
      type: FormFieldType.TEXT,
      placeholder: "Title",
      name: "title",
      required: false,
      valueAsNumber: false,
      label: "Title",
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Subheading",
      name: "subheading",
      required: false,
      valueAsNumber: false,
      label: "Subheading",
    },
    {
      type: FormFieldType.SELECT,
      placeholder: "Type",
      name: "type",
      required: true,
      valueAsNumber: false,
      label: "Type",
      options: [
        { label: "Invoice", value: "invoice" },
        { label: "Bill", value: "bill" },
      ],
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Document Number",
      name: "document_number",
      required: true,
      valueAsNumber: false,
      label: "Document Number",
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Order Number",
      name: "order_number",
      required: false,
      valueAsNumber: false,
      label: "Order Number",
    },
    {
      type: FormFieldType.DATE,
      placeholder: "Issued At",
      name: "issued_at",
      required: true,
      valueAsNumber: false,
      label: "Issued At",
    },
    {
      type: FormFieldType.DATE,
      placeholder: "Due At",
      name: "due_at",
      required: true,
      valueAsNumber: false,
      label: "Due At",
    },
    {
      type: FormFieldType.SELECT,
      placeholder: "Contact",
      name: "contact",
      required: true,
      valueAsNumber: false,
      label: "Contact",
      options: contactOptions,
    },
    {
      type: FormFieldType.NUMBER,
      placeholder: "Amount",
      name: "amount",
      required: true,
      valueAsNumber: true,
      label: "Amount",
    },
    {
      type: FormFieldType.NUMBER,
      placeholder: "Discount",
      name: "discount",
      required: false,
      valueAsNumber: true,
      label: "Discount",
    },
    {
      type: FormFieldType.SELECT,
      placeholder: "Discount Type",
      name: "discount_type",
      required: false,
      valueAsNumber: false,
      label: "Discount Type",
      options: [
        { label: "Percentage", value: "percentage" },
        { label: "Fixed", value: "fixed" },
      ],
    },
    {
      type: FormFieldType.SELECT,
      placeholder: "Currency",
      name: "currency",
      required: true,
      valueAsNumber: false,
      label: "Currency",
      options: currencyOptions,
    },
    {
      type: FormFieldType.SELECT,
      placeholder: "Category",
      name: "category",
      required: true,
      valueAsNumber: false,
      label: "Category",
      options: categoriesOptions,
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Notes",
      name: "notes",
      required: false,
      valueAsNumber: false,
      label: "Notes",
    },
  ];
};

const generateDocumentsUpdateInputs = (
  document: DocumentType | null,
  categories: Category[] | null,
  contacts: Contact[] | null,
  currecies: Currency[] | null
) => {
  if (!document || !categories) {
    return [];
  }

  const categoriesOptions = formatCategoryToSelectInput(categories);
  const contactOptions = formatContactToSelectInput(contacts);
  const currencyOptions = formatCurrencyToSelectInput(currecies);

  return [
    {
      type: FormFieldType.TEXT,
      placeholder: "Title",
      name: "title",
      required: false,
      valueAsNumber: false,
      label: "Title",
      defaultValue: document.title,
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Subheading",
      name: "subheading",
      required: false,
      valueAsNumber: false,
      label: "Subheading",
      defaultValue: document.subheading,
    },
    {
      type: FormFieldType.SELECT,
      placeholder: "Type",
      name: "type",
      required: true,
      valueAsNumber: false,
      label: "Type",
      options: [
        { label: "Invoice", value: "invoice" },
        { label: "Bill", value: "bill" },
      ],
      defaultValue: document.type,
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Document Number",
      name: "document_number",
      required: true,
      valueAsNumber: false,
      label: "Document Number",
      defaultValue: document.document_number,
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Order Number",
      name: "order_number",
      required: false,
      valueAsNumber: false,
      label: "Order Number",
      defaultValue: document.order_number,
    },
    {
      type: FormFieldType.DATE,
      placeholder: "Issued At",
      name: "issued_at",
      required: true,
      valueAsNumber: false,
      label: "Issued At",
      defaultValue: document.issued_at,
    },
    {
      type: FormFieldType.DATE,
      placeholder: "Due At",
      name: "due_at",
      required: true,
      valueAsNumber: false,
      label: "Due At",
      defaultValue: document.due_at,
    },
    {
      type: FormFieldType.SELECT,
      placeholder: "Contact",
      name: "contact",
      required: true,
      valueAsNumber: false,
      label: "Contact",
      defaultValue: document.contact,
      options: contactOptions,
    },
    {
      type: FormFieldType.NUMBER,
      placeholder: "Discount",
      name: "discount",
      required: false,
      valueAsNumber: true,
      label: "Discount",
    },
    {
      type: FormFieldType.SELECT,
      placeholder: "Discount Type",
      name: "discount_type",
      required: false,
      valueAsNumber: false,
      label: "Discount Type",
      options: [
        { label: "Percentage", value: "percentage" },
        { label: "Fixed", value: "fixed" },
      ],
      defaultValue: document.discount_type,
    },
    {
      type: FormFieldType.SELECT,
      placeholder: "Currency",
      name: "currency",
      required: true,
      valueAsNumber: false,
      label: "Currency",
      defaultValue: document.currency,
      options: currencyOptions,
    },
    {
      type: FormFieldType.SELECT,
      placeholder: "Category",
      name: "category",
      required: true,
      valueAsNumber: false,
      label: "Category",
      defaultValue: document?.category?.name,
      options: categoriesOptions,
    },
    {
      type: FormFieldType.NUMBER,
      placeholder: "Amount",
      name: "amount",
      required: true,
      valueAsNumber: true,
      label: "Amount",
      defaultValue: document.amount,
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Notes",
      name: "notes",
      required: false,
      valueAsNumber: false,
      label: "Notes",
      defaultValue: document.notes,
    },
  ];
};

const generatePagesInputs = () => {
  return [
    {
      type: FormFieldType.TEXT,
      placeholder: "Description",
      name: "description",
      required: true,
      valueAsNumber: false,
      label: "Description",
    },
  ];
};

const generatePagesUpdateInputs = (page: Page | null) => {
  if (!page) {
    return [];
  }

  return [
    {
      type: FormFieldType.TEXT,
      placeholder: "Description",
      name: "description",
      required: true,
      valueAsNumber: false,
      label: "Description",
      defaultValue: page.description,
    },
  ];
};

const generateDoubleEntryTaxInputs = () => {
  return [
    {
      type: FormFieldType.TEXT,
      placeholder: "Name",
      name: "name",
      required: true,
      valueAsNumber: false,
      label: "Name",
    },
    {
      type: FormFieldType.NUMBER,
      placeholder: "Rate",
      name: "rate",
      required: true,
      valueAsNumber: true,
      label: "Rate",
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Account Code",
      name: "account_code",
      required: true,
      valueAsNumber: false,
      label: "Account Code",
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Code",
      name: "code",
      required: true,
      valueAsNumber: false,
      label: "Code",
    },
    {
      type: FormFieldType.SELECT,
      placeholder: "Type",
      name: "type",
      required: true,
      valueAsNumber: false,
      label: "Type",
      options: [
        { label: "Input", value: "Input" },
        { label: "Output", value: "Output" },
        { label: "Reverse", value: "Reverse" },
      ],
    },
  ];
};

const generateDoubleEntryTaxUpdateInputs = (
  double_entry_account_tax: DoubleEntryTax | null
) => {
  if (!double_entry_account_tax) {
    return [];
  }
  return [
    {
      type: FormFieldType.TEXT,
      placeholder: "Name",
      name: "name",
      required: true,
      valueAsNumber: false,
      label: "Name",
      defaultValue: double_entry_account_tax.name,
    },
    {
      type: FormFieldType.NUMBER,
      placeholder: "Rate",
      name: "rate",
      required: true,
      valueAsNumber: true,
      label: "Rate",
      defaultValue: double_entry_account_tax.rate,
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Account Code",
      name: "account_code",
      required: true,
      valueAsNumber: false,
      label: "Account Code",
      defaultValue: double_entry_account_tax.account_code,
    },
    {
      type: FormFieldType.TEXT,
      placeholder: "Code",
      name: "code",
      required: true,
      valueAsNumber: false,
      label: "Code",
      defaultValue: double_entry_account_tax.code,
    },
    {
      type: FormFieldType.SELECT,
      placeholder: "Type",
      name: "type",
      required: true,
      valueAsNumber: false,
      label: "Type",
      defaultValue: double_entry_account_tax.type,
      options: [
        { label: "Input", value: "Input" },
        { label: "Output", value: "Output" },
        { label: "Reverse", value: "Reverse" },
      ],
    },
  ];
};

export {
  generateProfileInputs,
  generateRegisterInputs,
  generateCreateUserInputs,
  generateCompanyUpdateInputs,
  generateResetPasswordInputs,
  generateChangePasswordInputs,
  generateUpdateUserOrganizationInputs,
  generateItemInputs,
  generateItemUpdateInputs,
  generateAccountInputs,
  generateCategoriesInputs,
  generateCategoriesUpdatesInputs,
  generateContactsInputs,
  generateContactsUpdateInputs,
  generateDefaultsInputs,
  generateDepartmentsInputs,
  generateDepartmentsUpdateInputs,
  generateExchangeRatesInputs,
  generateExchangeRatesUpdateInputs,
  generateReconcliationInputs,
  generateReconcliationUpdateInputs,
  generateTaxesInputs,
  generateTaxesUpdateInputs,
  generateTransactionsInputs,
  generateDoubleEntryAccountUpdateInputs,
  generateDoubleEntryAccountsInputs,
  generateAccountUpdateInputs,
  generateEmployeeUpdateInputs,
  generateTransfersInputs,
  generateTransfersUpdateInputs,
  generateTransferUpdateInputs,
  generateEmployeeInputs,
  generateTransactionsUpdateInputs,
  generateDocumentsInputs,
  generateDocumentsUpdateInputs,
  generatePagesInputs,
  generatePagesUpdateInputs,
  generateDoubleEntryTaxInputs,
  generateDoubleEntryTaxUpdateInputs,
  generateSalaryInputs,
  generateSalaryUpdateInputs,
};
