import React, { Fragment, useEffect, useState } from "react";
import GenericApi from "@/api/genericApi";
import { PaySlipQueryKey, SalaryQueryKey } from "@/config/constants/QueryKeys";
import { ApiRoutes } from "@/config/routes/ApiRoutes";
import { ApiResponse, ApiError } from "@/types/Api";
import { FormError, ValidationErrors } from "@/types/ValidationError";
import CustomLogger from "@/utils/CustomLogger";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useSnackBarAlert } from "@/hooks/useSnackbar";
import { AxiosError, isAxiosError } from "axios";
import { AdaptiveModal, LoadingSpinner } from "..";
import { CustomModalPosition } from "@/types/Modal";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import FormField from "../CustomForm/FormField";
import { generateSalaryUpdateInputs } from "@/utils/generateInputs";
import {
  SalaryType,
  CreateSalaryType,
  CreateSalaryTypeSchema,
} from "@/types/Salary";
import { useNavigate } from "react-router-dom";
import { RoutesPathList } from "@/config/routes/Routes";
import { useSelector } from "react-redux";
import { RootState } from "@/store";
import { getUser } from "@/utils/localStorage";

export interface SalariesItemProps {
  //modificat ***
  salaries: SalaryType; //modificat ***
  onClick: () => void; //modificat ***
}

const formatDate = (timezoneDate: string) => {
  const date = new Date(timezoneDate);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
};

const SalaryItem: React.FC<SalariesItemProps> = ({ salaries, onClick }) => {
  //modificat ***

  const user = getUser();
  const { selectedCompany } = useSelector((state: RootState) => state.company);
  const permissions = useSelector(
    (state: RootState) => state.permissions.permissions
  );

  const permissionExists = permissions.find(
    (p) => p.company === selectedCompany?.id
  );

  let edit_accounting_and_payroll =
    permissionExists?.edit_accounting_and_payroll;

  if (user?.is_organization) {
    edit_accounting_and_payroll = true;
  }

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<CreateSalaryType>({
    //modificat ***
    resolver: zodResolver(CreateSalaryTypeSchema), //modificat ***
  });

  console.log({
    errors,
  });

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { showSnackBar } = useSnackBarAlert();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);

  const deleteSalariesMutation = useMutation<
    ApiResponse<{ message: string }>,
    ApiError<ValidationErrors>,
    { id: number }
  >({ //modificat ***
    mutationFn: (data) =>
      GenericApi.delete<{ message: string }>(`${ApiRoutes.SALARY}/${data.id}`), //modificat ***
    onSuccess: async ({ data }) => {
      CustomLogger.log(data);
      queryClient.invalidateQueries({ queryKey: [SalaryQueryKey] }); //modificat ***
      queryClient.invalidateQueries({ queryKey: [PaySlipQueryKey] });
      showSnackBar(
        "Salary deleted",
        "success",
        { vertical: "top", horizontal: "right" },
        3000
      ); //modificat ***
    },
    onError: async ({ error }) => {
      CustomLogger.error(error);
      handleAxiosError(error);
      showSnackBar(
        "Error deleting Salary",
        "error",
        { vertical: "top", horizontal: "right" },
        3000
      ); //modificat ***
    },
  });

  const amountFormattig = () => {
    const amount = watch("gross_salary");
    if (amount && amount.includes(",")) {
      setValue("gross_salary", amount.replace(/,/g, ""));
    }
  };

  useEffect(() => {
    amountFormattig();
  });

  const handleAxiosError = (error: AxiosError<unknown> | null | Error) => {
    if (isAxiosError<FormError>(error)) {
      console.log(error);
      return;
    }

    if (isAxiosError<ValidationErrors>(error)) {
      console.log(error);
      return;
    }

    console.log(error);
  };

  const handleDelete = (id: number) => {
    deleteSalariesMutation.mutate({ id }); //modificat ***
  };

  const toggleDeleteModal = () => {
    setDeleteModalOpen(!deleteModalOpen);
  };

  const toggleEditModal = () => {
    setEditModalOpen(!editModalOpen);
  };

  const updateSalariesMutation = useMutation<
    ApiResponse<SalaryType>,
    ApiError<ValidationErrors>,
    CreateSalaryType
  >({ //modificat ***
    mutationFn: (data) =>
      GenericApi.put<SalaryType>(`${ApiRoutes.SALARY}/${salaries.id}`, data), //modificat ***
    onSuccess: async ({ data }) => {
      CustomLogger.log(data);
      setEditModalOpen(false);
      queryClient.invalidateQueries({ queryKey: [SalaryQueryKey] }); //modificat ***
      queryClient.invalidateQueries({ queryKey: [PaySlipQueryKey] });
      showSnackBar(
        "Salary updated",
        "success",
        { vertical: "top", horizontal: "right" },
        3000
      ); //modificat ***
    },
    onError: async ({ error }) => {
      CustomLogger.error(error);
      handleAxiosError(error);
      showSnackBar(
        "Error updating Salary",
        "error",
        { vertical: "top", horizontal: "right" },
        3000
      ); //modificat ***
    },
  });

  const updateSalariesInputs = generateSalaryUpdateInputs(salaries); //modificat ***

  const onSubmit = (data: CreateSalaryType) => {
    //modificat ***
    updateSalariesMutation.mutate({
      ...data,
      holiday_fund: Number(data.holiday_fund)
    } as unknown as SalaryType); //modificat ***
  };

  const inputStyle = (inputName: string) => {
    if (inputName === "salary_13th") {
      return "w-5 mt-1";
    }
    return "mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500";
  };

  return (
    <Fragment>
      <tr
        onClick={() => onClick()}
        className="border-b dark:border-gray-700 hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer"
      >
        <td
          onClick={() => navigate(`${RoutesPathList.PayrollPage}`)}
          className="px-4 py-3 text-left"
        >
          {salaries.first_name}
        </td>
        <td
          onClick={() => navigate(`${RoutesPathList.PayrollPage}`)}
          className="px-4 py-3 text-left"
        >
          {salaries.last_name}
        </td>
        <td
          onClick={() => navigate(`${RoutesPathList.PayrollPage}`)}
          className="px-4 py-3 text-left"
        >
          {salaries.gross_salary}
        </td>
        <td
          onClick={() => navigate(`${RoutesPathList.PayrollPage}`)}
          className="px-4 py-3 text-left"
        >
          {salaries.id_no}
        </td>
        <td
          onClick={() => navigate(`${RoutesPathList.PayrollPage}`)}
          className="px-4 py-3 text-left"
        >
          {salaries.tax_id_no}
        </td>
        <td
          onClick={() => navigate(`${RoutesPathList.PayrollPage}`)}
          className="px-4 py-3 text-left"
        >
          {salaries.si_no}
        </td>
        <td
          onClick={() => navigate(`${RoutesPathList.PayrollPage}`)}
          className="px-4 py-3 text-left"
        >
          {formatDate(salaries.hired_at)}
        </td>
        <td
          onClick={() => navigate(`${RoutesPathList.PayrollPage}`)}
          className="px-4 py-3 text-center"
        >
          {salaries.salary_13th ? "✔️" : "❌"}
        </td>
        {edit_accounting_and_payroll ? <td className="px-4 py-3 text-right">
          <button
            onClick={toggleEditModal}
            className="font-medium text-green-400 dark:text-green-400 hover:underline"
          >
            Edit
          </button>
          <span className="mx-2">|</span>
          <button
            onClick={toggleDeleteModal}
            className="font-medium text-red-600 dark:text-red-500 hover:underline"
          >
            Delete
          </button>
        </td> : null}
      </tr>

      <AdaptiveModal
        isOpen={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        title={
          <h2 className="text-lg font-semibold text-gray-900 dark:text-white">
            Delete SalaryType
          </h2>
        }
        position={CustomModalPosition.Center}
        footer={
          <Fragment>
            <button
              onClick={() => handleDelete(salaries.id)}
              className="bg-red-600 text-white px-3 py-1.5 rounded-lg hover:bg-red-700"
            >
              {
                deleteSalariesMutation.isPending ? (
                  <LoadingSpinner variant="danger" />
                ) : (
                  "Delete"
                ) //modificat ***
              }
            </button>
            <button
              onClick={toggleDeleteModal}
              className="bg-gray-200 text-gray-800 px-3 py-1.5 rounded-lg hover:bg-gray-300"
            >
              Cancel
            </button>
          </Fragment>
        }
      >
        <p className="text-gray-700 dark:text-gray-300">
          Are you sure you want to delete this person?
        </p>
        {/* modifica *** */}
      </AdaptiveModal>

      <AdaptiveModal
        isOpen={editModalOpen}
        onClose={() => setEditModalOpen(false)}
        title={
          <h2 className="text-lg font-semibold text-gray-900 dark:text-white">
            Edit SalaryType
          </h2>
        }
        position={CustomModalPosition.Center}
      >
        <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
          {updateSalariesInputs.map((input) => (
            <FormField<CreateSalaryType> //modificat ***
              key={input.name}
              type={input.type}
              placeholder={input.placeholder}
              name={input.name}
              required={input.required}
              valueAsNumber={input.valueAsNumber}
              inputStyle={inputStyle(input.name)}
              label={input.label}
              defaultValue={input?.defaultValue ?? ""}
              register={register}
              error={errors[input.name as keyof CreateSalaryType]} //modificat ***
            />
          ))}
          <button
            type="submit"
            className="flex w-full items-center justify-center text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
          >
            {
              updateSalariesMutation.isPending ? (
                <svg
                  className="w-5 h-5 mr-3 border-r-2 border-white rounded-full animate-spin"
                  viewBox="0 0 24 24"
                ></svg>
              ) : (
                "Edit Salary"
              )
              //modificat ***
            }
          </button>
        </form>
      </AdaptiveModal>
    </Fragment>
  );
};

export default SalaryItem;
