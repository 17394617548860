import GenericApi from "@/api/genericApi";
import { PaySlipQueryKey } from "@/config/constants/QueryKeys";
import { useSnackBarAlert } from "@/hooks/useSnackbar";
import { RootState } from "@/store";
import { ApiError, ApiResponse } from "@/types/Api";
import {
  CreatePostPayslip,
  Payslip,
  PaySlipCreate,
  PaySlipGet,
} from "@/types/PaySlip";
import { FormError, ValidationErrors } from "@/types/ValidationError";
import CustomLogger from "@/utils/CustomLogger";
import { getVal } from "@/utils/utils";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AdaptiveModal from "../AdaptiveModal";
import { CustomModalPosition } from "@/types/Modal";
import { AxiosError, isAxiosError } from "axios";

type CreatePayslip = {
  company_id: number;
  months: number;
  employee_id: number;
  payment_period: string;
};

const PayrollPage = () => {
  const { showSnackBar } = useSnackBarAlert();
  const [showCalculations, setShowCalculations] = useState(false);
  const [donations, setDonations] = useState(0);
  const [subscriptionFees, setSubscriptionFees] = useState(0);
  const [lifeInsurance, setLifeInsurance] = useState(0);
  const [providentFund, setProvidentFund] = useState(0);
  const [familyInsurance, setFamilyInsurance] = useState(0);
  const [medicalInsurance, setMedicalInsurance] = useState(0);
  const [months, setMonths] = useState(0);
  const [paymentPeriod, setPaymentPeriod] = useState("");
  const [debitSalary, setDebitSalary] = useState("");
  const [debitSocial, setDebitSocial] = useState("");
  const [creditAccrued, setCreditAccrued] = useState("");
  const [modal, setModal] = useState(false);
  const [expatriate, setExpatriate] = useState(0);

  const [payslipData, setPayslipData] = useState<
    ApiResponse<Payslip[]> | undefined
  >(undefined);
  const queryClient = useQueryClient();
  const selectedCompany = useSelector(
    (state: RootState) => state.company.selectedCompany
  );

  const payrollPage = useSelector((state: RootState) => state.payrollPage.id);

  const handleCalculate = () => {
    setShowCalculations(true);
  };

  const payslipGetRoute = `/payslip/company/${selectedCompany?.id}`;

  const payslipRoute = selectedCompany
    ? `/payslip/company/${selectedCompany?.id}`
    : "/not-found";

  const { data: payslipGet } = useQuery<ApiResponse<PaySlipGet[]>>({
    queryKey: [PaySlipQueryKey],
    queryFn: async () => GenericApi.get<PaySlipGet[]>(payslipGetRoute),
  });

  const account = payslipGet?.data?.find(
    (payslip) => payslip.id === payrollPage
  );

  console.log(payslipGet?.data, "payslipGet");

  const addPayslipMutation = useMutation<
    ApiResponse<Payslip[]>,
    ApiError<ValidationErrors>,
    PaySlipCreate
  >({
    mutationFn: (data) => GenericApi.post<Payslip[]>(payslipRoute, data), //schimbare tipuri + ruta noua
    onSuccess: async (response) => {
      CustomLogger.log(response.data);
      setPayslipData(response);
      queryClient.invalidateQueries({ queryKey: [PaySlipQueryKey] }); //adaugat + schimbare chei
    },
    onError: async ({ error }) => {
      CustomLogger.error(error);
    },
  });

  const [errorPayslip, setErrorPayslip] = useState<string | null>(null);

  const addPayslip = useMutation<
    ApiResponse<BlobPart>,
    ApiError<ValidationErrors>,
    CreatePayslip
  >({
    mutationFn: (data) =>
      GenericApi.post<BlobPart>(`/payslip/pdf`, data, "blob"),
    onSuccess: async ({ data }) => {
      CustomLogger.log(data);
      const filename = "payslip.pdf";
      if (data) {
        const linkUrl = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = linkUrl;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
      }
    },
    onError: async ({ error }) => {
      CustomLogger.error(error);

      handleAxiosError(error);
    },
  });

  const handleAxiosError = (error: AxiosError<unknown> | null | Error) => {
    const errorMessage = "Something went wrong";

    if (isAxiosError<FormError>(error)) {
      console.log(error);
      setErrorPayslip(error?.response?.data?.detail ?? errorMessage);
      return;
    }

    if (isAxiosError<ValidationErrors>(error)) {
      console.log(error);
    }

    setErrorPayslip(errorMessage);
  };

  // const countMonths = (hiredAt: string) => {
  //   const start = new Date(hiredAt);
  //   const end = new Date(start.getFullYear(), 11, 31);

  //   if (start > new Date()) {
  //     return 0;
  //   }

  //   const months =
  //     (end.getFullYear() - start.getFullYear()) * 12 +
  //     (end.getMonth() - start.getMonth());

  //   if (months < 0 || (months === 0 && start.getDate() > end.getDate())) {
  //     return 0;
  //   }

  //   return months + 1; // Include the starting month
  // };

  // useEffect(() => {
  //   if (account?.hired_at) {
  //     const mth = countMonths(account.hired_at);
  //     setMonths(mth);
  //   }
  // }, [account?.hired_at]);

  
  const payslipDataGet = payslipGet?.data?.find(
    (payslip) => payslip.id === payrollPage
  );
  const payslip = payslipData?.data?.find(
    (payslip) => payslip.employee_id === payrollPage
  );
  const payee = payslip?.tax_details?.accumulated_tax_total
    ? payslip?.tax_details?.accumulated_tax_total / 12
    : 0;
  console.log(payee, "payee");

//   const countWorkingDays = (hiredAt: string, paymentPeriod: string) => {
//     const start = new Date(hiredAt); 
//     // const end = new Date(start.getFullYear(), start.getMonth() + 1, 0);
//     const end = new Date(paymentPeriod) 

//     if (start > end) {
//         return 0; 
//     }

//     let workingDays = 0;

//     for (
//         let date = new Date(start);
//         date <= end;
//         date.setDate(date.getDate() + 1)
//     ) {
//         const day = date.getDay();
//         if (day !== 0 && day !== 6) {
//             workingDays++;
//         }
//     }

//     return workingDays;
// };

// const countWorkingDays = (hiredAt: string, paymentPeriod: string) => {
//   const currentYear = new Date().getFullYear(); // Anul curent
//   const hireDate = new Date(hiredAt); // Data angajării
//   const end = new Date(paymentPeriod); // Sfârșitul perioadei de plată

//   const start =
//       hireDate.getFullYear() < currentYear
//           ? new Date(currentYear, 0, 1) 
//           : new Date(hireDate); 

//   if (start > end) {
//       return 0;
//   }

//   let workingDays = 0;

//   for (
//       let date = new Date(start);
//       date <= end;
//       date.setDate(date.getDate() + 1)
//   ) {
//       const day = date.getDay();
//       if (day !== 0 && day !== 6) {
//           workingDays++;
//       }
//   }

//   const daysInPeriod = (end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24) + 1;
//   const maxWorkingDays = Math.min(21, Math.floor((daysInPeriod / 30) * 21)); 
//   return Math.min(workingDays, maxWorkingDays);
// };

const countWorkingDays = (hiredAt: string, paymentPeriod: string) => {
  const hireDate = new Date(hiredAt);
  const end = new Date(paymentPeriod);

  const start = hireDate > end ? end : hireDate; // Ensure start is before or equal to end

  if (start > end) {
    return 0;
  }

  let workingDays = 0;

  for (let date = new Date(start); date <= end; date.setDate(date.getDate() + 1)) {
    const day = date.getDay();
    if (day !== 0 && day !== 6) {
      workingDays++;
    }
  }

  const daysInPeriod = (end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24) + 1;
  const maxWorkingDays = Math.min(21, Math.floor((daysInPeriod / 30) * 21));
  
  return Math.min(workingDays, maxWorkingDays);
};



useEffect(() => {
    const hired_at = account?.hired_at
    if (paymentPeriod && hired_at) {
                
        const workingDays = countWorkingDays(hired_at, paymentPeriod);
        const totalMonths = payslipDataGet?.salary_13th
            ? workingDays
            : workingDays;
        console.log(totalMonths,'daysssss')
        setMonths(totalMonths)
    }
}, [paymentPeriod, payslipDataGet?.salary_13th,account?.hired_at]);


const getDaysInMonth = (hiredAt: string) => {
  const start = new Date(hiredAt);
  const year = start.getFullYear();
  const month = start.getMonth();

  return new Date(year, month + 1, 0).getDate();
};

  const days_month = getDaysInMonth(paymentPeriod)
  
  const tax_monthly = payslip ? payslip.tax_details.accumulated_tax_total / 12 : 0


  const handlePayslip = () => {
    if (selectedCompany?.id && payslipDataGet?.id) {
      const payload = {
        company_id: selectedCompany.id,
        employee_id: payslipDataGet?.id,
        months: months,
        payment_period: paymentPeriod,
        day_month:days_month,
        tax_monthly: tax_monthly
      };
      console.log(payload, "HANDLEPAYSLIP");
      setErrorPayslip(null);
      addPayslip.mutate(payload);
    }
  };


  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (paymentPeriod.length > 0) {
      console.log("Data is Valid");
    } else {
      showSnackBar(
        "You need to provide a payment period", //schimb nume ***
        "error",
        { vertical: "top", horizontal: "right" },
        3000
      );
      return;
    }

    if (months > 0) {
      console.log("Data is Valid");
    } else {
      showSnackBar(
        "Work days must be more than 0.", //schimb nume ***
        "error",
        { vertical: "top", horizontal: "right" },
        3000
      );
      return;
    }

    const payload = {
      donation: donations,
      subscription_fees: subscriptionFees,
      life_insurance: lifeInsurance,
      providence_fund: providentFund,
      family_insurance: familyInsurance,
      medical_insurance: medicalInsurance,
      payee: payee,
      expatriate: expatriate,
      employee_id: payslipDataGet?.id,
      months: months,
      payment_period: paymentPeriod,
      day_month:days_month,
      tax_monthly: tax_monthly
    };
    console.log(payload);
    addPayslipMutation.mutate(payload);
  };

  const postRoute = `/payslip/create-journal/${selectedCompany?.id}`;

  const addPostMutatuion = useMutation<
    ApiResponse<CreatePostPayslip[]>,
    ApiError<ValidationErrors>,
    CreatePostPayslip
  >({
    mutationFn: (data) => GenericApi.post<CreatePostPayslip[]>(postRoute, data), //schimbare tipuri + ruta noua
    onSuccess: async (response) => {
      CustomLogger.log(response.data);
      setModal(false);
      showSnackBar(
        "Payslip Created", //schimb nume ***
        "success",
        { vertical: "top", horizontal: "right" },
        3000
      );
      queryClient.invalidateQueries({ queryKey: [PaySlipQueryKey] }); //adaugat + schimbare chei
    },
    onError: async ({ error }) => {
      CustomLogger.error(error);
      showSnackBar(
        "One or more accounts codes are incorrect or are used in multiple accounts.",
        "error",
        { vertical: "top", horizontal: "right" },
        3000
      );
    },
  });

  const handlePostMutation = (e: React.SyntheticEvent) => {
    e.preventDefault();
    const payload = {
      employee_id: payslip?.employee_id ?? 0,
      company_id: selectedCompany?.id,
      payment_period: paymentPeriod,
      debit_salary: debitSalary,
      debit_social: debitSocial,
      credit: creditAccrued,
    };
    addPostMutatuion.mutate(payload);
    queryClient.invalidateQueries({ queryKey: ["count"] });
  };

  const toogleModal = () => {
    setModal(!modal);
  };

  return (
    <div className="grid grid-cols-3 mt-20">
      <div className="flex flex-col items-center gap-10 bg-white shadow-lg dark:bg-gray-800 rounded-lg p-8 w-full">
        <h1 className="text-2xl w-full text-left font-bold dark:text-white mb-6">
          Payroll
        </h1>

        <div className="flex gap-5">
          <div className="w-full mb-4">
            <label className="block text-gray-700 dark:text-white text-sm font-bold mb-2">
              First Name
            </label>

            <div className="flex space-x-4">
              <input
                className="border rounded-md w-full p-2"
                type="text"
                placeholder="Employee First Name"
                value={payslipDataGet ? payslipDataGet.first_name : ""}
                disabled
              />
            </div>
          </div>
          <div className="w-full mb-4">
            <label className="block  dark:text-white text-gray-700 text-sm font-bold mb-2">
              Last Name
            </label>

            <div className="flex space-x-4">
              <input
                className="border rounded-md w-full p-2"
                type="text"
                placeholder="Employee Last Name"
                value={payslipDataGet ? payslipDataGet.last_name : ""}
                disabled
              />
            </div>
          </div>
        </div>

        <div className="w-full mb-6">
          <div className="grid grid-cols-3 gap-4 text-left">
            <div>
              <label className="block  dark:text-white text-gray-700 text-sm font-bold mb-5">
                Salary
              </label>
              <label className="block  dark:text-white text-gray-700 text-sm font-bold mb-5">
                GROSS
              </label>
              <label className="block  dark:text-white text-gray-700 text-sm font-bold">
                NET
              </label>
            </div>
            <div>
              <label className="block  dark:text-white text-gray-500 text-sm mb-1">
                Monthly
              </label>
              <div className="border dark:text-white p-2 rounded-md text-center mb-2">
                €{payslipDataGet?.gross_salary || "0.00"}
              </div>
              <div className="border dark:text-white p-2 rounded-md text-center">
                €{payslip?.salary_details.net_salary || "0.00"}
              </div>
            </div>
            <div>
              <label className="block dark:text-white text-gray-500 text-sm mb-1">
                Annually
              </label>
              <div className="border p-2 dark:text-white rounded-md text-center mb-2">
                €{payslipDataGet?.annual_salary || "0.00"}
              </div>
              <div className="border p-2 dark:text-white rounded-md text-center">
                €{payslip?.salary_details.net_salary_annual || "0.00"}
              </div>
            </div>
          </div>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="flex gap-5">
            <div className="w-full mb-4">
              <label className="block text-gray-700 dark:text-white text-sm font-bold mb-2">
                DONATIONS
              </label>

              <div className="flex space-x-4">
                <input
                  className="border rounded-md w-full p-2"
                  type="text"
                  placeholder="Donations"
                  value={getVal(donations)}
                  onChange={(e) => setDonations(Number(e.target.value))}
                />
              </div>
            </div>
            <div className="w-full mb-4">
              <label className="block text-gray-700 dark:text-white text-sm font-bold mb-2">
                SUBSCRIPTION FEES
              </label>

              <div className="flex space-x-4">
                <input
                  className="border rounded-md w-full p-2"
                  type="text"
                  placeholder="Employee Last Name"
                  value={getVal(subscriptionFees)}
                  onChange={(e) => setSubscriptionFees(Number(e.target.value))}
                />
              </div>
            </div>
          </div>
          <div className="flex gap-5">
            <div className="w-full mb-4">
              <label className="block text-gray-700 dark:text-white text-sm font-bold mb-2">
                LIFE INSURANCE
              </label>

              <div className="flex space-x-4">
                <input
                  className="border rounded-md w-full p-2"
                  type="text"
                  placeholder="Life Insurance"
                  value={getVal(lifeInsurance)}
                  onChange={(e) => setLifeInsurance(Number(e.target.value))}
                />
              </div>
            </div>
            <div className="w-full mb-4">
              <label className="block text-gray-700 dark:text-white text-sm font-bold mb-2">
                PROVIDENT FUND
              </label>

              <div className="flex space-x-4">
                <input
                  className="border rounded-md w-full p-2"
                  type="text"
                  placeholder="Provident Fund"
                  value={getVal(providentFund)}
                  onChange={(e) => setProvidentFund(Number(e.target.value))}
                />
              </div>
            </div>
          </div>
          <div className="flex gap-5">
            <div className="w-full mb-4">
              <label className="block text-gray-700 dark:text-white text-sm font-bold mb-2">
                FAMILY INSURANCE
              </label>

              <div className="flex space-x-4">
                <input
                  className="border rounded-md w-full p-2"
                  type="text"
                  placeholder="Family Insurance"
                  value={getVal(familyInsurance)}
                  onChange={(e) => setFamilyInsurance(Number(e.target.value))}
                />
              </div>
            </div>
            <div className="w-full mb-4">
              <label className="block text-gray-700 dark:text-white text-sm font-bold mb-2">
                MEDICAL INSURANCE
              </label>

              <div className="flex space-x-4">
                <input
                  className="border rounded-md w-full p-2"
                  type="text"
                  placeholder="Medical Insurance"
                  value={getVal(medicalInsurance)}
                  onChange={(e) => setMedicalInsurance(Number(e.target.value))}
                />
              </div>
            </div>
          </div>

          <div className="flex gap-5">
            <div className="w-full mb-4">
              <label className="block text-gray-700 dark:text-white text-sm font-bold mb-2">
                WORK DAYS
              </label>

              <div className="flex space-x-4">
                <input
                  className="border rounded-md w-full p-2"
                  type="number"
                  placeholder="Months"
                  value={getVal(months)}
                  onChange={(e) => setMonths(Number(e.target.value))}
                  readOnly
                />
              </div>
            </div>
            <div className="w-full mb-4">
              <label className="block text-gray-700 dark:text-white text-sm font-bold mb-2">
                PAYMENT PERIOD
              </label>

              <div className="flex space-x-4">
                <input
                  className="border rounded-md w-full p-2"
                  type="date"
                  placeholder="Payment Period"
                  value={paymentPeriod}
                  max="9999-12-31"
                  onChange={(e) => setPaymentPeriod(e.target.value)}
                  // onKeyDown={(e) => e.preventDefault()}
                />
              </div>
            </div>
          </div>

          <div className="w-full mb-4">
            <label className="block text-gray-700 dark:text-white text-sm font-bold mb-2">
              EXPATRIATE RELIEF
            </label>

            <div className="flex space-x-4">
              <input
                className="border rounded-md w-full p-2"
                type="number"
                placeholder="Payment Period"
                value={getVal(expatriate)}
                onChange={(e) => setExpatriate(Number(e.target.value))}
              />
            </div>
          </div>

          <button
            onClick={handleCalculate}
            className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-md"
          >
            Calculate
          </button>
        </form>
      </div>

      {showCalculations && payslip && (
        <div className="p-8 col-span-2">
          <div className="mb-8">
            <div className="flex w-full justify-between">
              <h2 className="text-2xl font-bold mb-4 w-full dark:text-white">
                Contributions
              </h2>
              <h2 className="text-2xl font-bold mb-4 w-full text-center dark:text-white">
                Employer
              </h2>
              <h2 className="text-2xl font-bold mb-4 w-full text-center dark:text-white">
                Employee
              </h2>
            </div>
            <table className="min-w-full table-auto border-collapse border border-gray-200">
              <thead className="bg-blue-100">
                <tr>
                  <th className="border border-gray-300  p-2">
                    SI Contributions
                  </th>
                  <th className="border border-gray-300 p-2 text-center">
                    Rate
                  </th>
                  <th className="border border-gray-300 p-2 text-center">
                    Amount
                  </th>
                  <th className="border border-gray-300 p-2 text-center">
                    Rate
                  </th>
                  <th className="border border-gray-300 p-2 text-center">
                    Amount
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="border border-gray-300 p-2 dark:text-white">
                    SI Employer
                  </td>
                  <td className="border border-gray-300 p-2 text-center dark:text-white">
                    8.80%
                  </td>
                  <td className="border border-gray-300 p-2 text-center dark:text-white">
                    €{payslip.employer_contributions.si_employer_amount}
                  </td>
                  <td className="border border-gray-300 p-2 text-center dark:text-white">
                    8.80%
                  </td>
                  <td className="border border-gray-300 p-2 text-center dark:text-white">
                    €{payslip.employee_contributions.si_employee_amount}
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-300 p-2 dark:text-white">
                    Cohesion Fund
                  </td>
                  <td className="border border-gray-300 p-2 text-center dark:text-white">
                    2.00%
                  </td>
                  <td className="border border-gray-300 p-2 text-center dark:text-white">
                    €{payslip.employer_contributions.cohesion_fund_amount}
                  </td>
                  <td className="border border-gray-300 p-2 text-center dark:text-white">
                    2.00%
                  </td>
                  <td className="border border-gray-300 p-2 text-center dark:text-white">
                    €
                    {
                      payslip.employee_contributions
                        .cohesion_fund_employee_amount
                    }
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-300 p-2 dark:text-white">
                    Industrial Training
                  </td>
                  <td className="border border-gray-300 p-2 text-center dark:text-white">
                    0.50%
                  </td>
                  <td className="border border-gray-300 p-2 text-center dark:text-white">
                    €{payslip.employer_contributions.industrial_training_amount}
                  </td>
                  <td className="border border-gray-300 p-2 text-center dark:text-white">
                    0.50%
                  </td>
                  <td className="border border-gray-300 p-2 text-center dark:text-white">
                    €
                    {
                      payslip.employee_contributions
                        .industrial_training_employee_amount
                    }
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-300 p-2 dark:text-white">
                    Redundancy fund
                  </td>
                  <td className="border border-gray-300 p-2 text-center dark:text-white">
                    1.20%
                  </td>
                  <td className="border border-gray-300 p-2 text-center dark:text-white">
                    €{payslip.employer_contributions.providence_fund_amount}
                  </td>
                  <td className="border border-gray-300 p-2 text-center dark:text-white">
                    1.20%
                  </td>
                  <td className="border border-gray-300 p-2 text-center dark:text-white">
                    €
                    {
                      payslip.employee_contributions
                        .providence_fund_employee_amount
                    }
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-300 p-2 dark:text-white">
                    GESY Employers
                  </td>
                  <td className="border border-gray-300 p-2 text-center dark:text-white">
                    2.90%
                  </td>
                  <td className="border border-gray-300 p-2 text-center dark:text-white">
                    €{payslip.employer_contributions.gesy_employers_amount}
                  </td>
                  <td className="border border-gray-300 p-2 text-center dark:text-white">
                    2.90%
                  </td>
                  <td className="border border-gray-300 p-2 text-center dark:text-white">
                    €{payslip.employee_contributions.gesy_employee_amount}
                  </td>
                </tr>
                <tr className="font-bold">
                  <td className="border border-gray-300 p-2 dark:text-white">
                    Total
                  </td>
                  <td
                    className="border border-gray-300 p-2 text-right dark:text-white"
                    colSpan={2}
                  >
                    €{payslip.total_contributions.employer}
                  </td>
                  <td
                    className="border border-gray-300 p-2 text-right dark:text-white"
                    colSpan={2}
                  >
                    €{payslip.total_contributions.employee}
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="text-right mt-2 font-bold dark:text-white">
              Payable: €{payslip.total_contributions.payable}
            </div>
          </div>
          <div className="mb-8 flex flex-col gap-10">
            <h2 className="text-2xl font-bold mb-4 dark:text-white">
              Tax Calculation
            </h2>
            <div className="grid grid-cols-2 gap-2 dark:text-white">
              <div className="font-bold">Donation</div>
              <div className="text-left">
                €{payslip.tax_calculation.less_subscription.donation}
              </div>
              <div className="font-bold dark:text-white">Subscription Fees</div>
              <div className="text-left dark:text-white">
                €{payslip.tax_calculation.less_subscription.subscription_fees}
              </div>
              <div className="font-bold"></div>
              <div className="text-left font-bold dark:text-white">
                €{payslip.tax_calculation.less_subscription.total_less}
              </div>
            </div>
            <div className="grid grid-cols-2 gap-2">
              <div className="font-bold dark:text-white">
                Less Life Insurances{" "}
                <span className="font-normal dark:text-white">
                  (*Capped to 1/5 of taxable profit)
                </span>
              </div>
              <div></div>
              <div className="font-bold dark:text-white">Life insurance</div>
              <div className="text-left dark:text-white">
                €{payslip.tax_calculation.less_insurances.life_insurance}
              </div>
              <div className="font-bold dark:text-white">Providend fund</div>
              <div className="text-left dark:text-white">
                €{payslip.tax_calculation.less_insurances.providend_fund}
              </div>
              <div className="font-bold dark:text-white">Family Insurance</div>
              <div className="text-left dark:text-white">
                €{payslip.tax_calculation.less_insurances.family}
              </div>
              <div className="font-bold dark:text-white">Medical Insurance</div>
              <div className="text-left dark:text-white">
                €{payslip.tax_calculation.less_insurances.medical}
              </div>
              <div className="font-bold dark:text-white">
                Social Insurance Contributions
              </div>
              <div className="text-left dark:text-white">
                €{payslip.tax_calculation.less_subscription.social_insurance}
              </div>
              <div className="font-bold dark:text-white">NHS Contrinutions</div>
              <div className="text-left dark:text-white">
                €{payslip.tax_calculation.less_subscription.nhs_contribution}
              </div>
              <div className="font-bold"></div>
              <div className="text-left font-bold dark:text-white">
                €{payslip.tax_calculation.less_insurances.total_less_insurances}
              </div>
            </div>
            <div className="grid grid-cols-2 gap-2">
              <div className="font-bold dark:text-white">
                Taxable Profit for the year
              </div>
              <div className="text-left font-bold dark:text-white">
                €{payslip.taxable_profit_year}
              </div>
              <div className="font-bold"></div>
              {/* <div className="text-left font-bold">€{payslip.tax_total}</div> */}
            </div>
          </div>

          {/* Tax Brackets Section */}
          <div className="mb-8">
            <h2 className="text-2xl font-bold mb-4 dark:text-white">
              Tax Brackets
            </h2>
            <table className="min-w-full table-auto border-collapse border border-gray-200">
              <thead className="bg-blue-100">
                <tr>
                  <th className="border border-gray-300 p-2">Lower Bound</th>
                  <th className="border border-gray-300 p-2">Upper Bound</th>
                  <th className="border border-gray-300 p-2">Rate</th>
                  <th className="border border-gray-300 p-2">Taxable Profit</th>
                  <th className="border border-gray-300 p-2">
                    Accumulated Tax
                  </th>
                </tr>
              </thead>
              <tbody>
                {payslip.tax_details.brackets.map((bracket, index) => (
                  <tr key={index}>
                    <td className="border border-gray-300 p-2 dark:text-white">
                      {bracket.lower_bound}
                    </td>
                    <td className="border border-gray-300 p-2 dark:text-white">
                      {String(bracket.upper_bound) !== "Infinity"
                        ? bracket.upper_bound
                        : "Above 60001"}
                    </td>
                    <td className="border border-gray-300 p-2 dark:text-white">
                      {bracket.tax_rate * 100}%
                    </td>
                    <td className="border border-gray-300 p-2 dark:text-white">
                      {bracket.taxable_profit}
                    </td>
                    <td className="border border-gray-300 p-2 dark:text-white">
                      {bracket.accumulated_tax}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="mt-2 text-right font-bold dark:text-white">
              Total Tax for the year: €
              {payslip.tax_details.accumulated_tax_total}
            </div>
            <div className="mt-2 text-right font-bold dark:text-white">
              Total Tax for a month: €
              {(payslip.tax_details.accumulated_tax_total / 12).toFixed(2)}
            </div>
            <div className="mt-2 text-right font-bold dark:text-white">
              Less: Provisional Tax paid: 0.00 €
            </div>
            <div className="mt-2 text-right font-bold dark:text-white">
              Total Tax payable: €{payslip.tax_details.total_tax_payable}
            </div>
          </div>
          <div className="text-end flex gap-2 justify-end">
            {errorPayslip ? (
              <p className="text-red-500">{errorPayslip}</p>
            ) : null}
            <button
              // onClick={handlePayslip}
              onClick={toogleModal}
              className={
                addPayslip.isPending
                  ? "bg-gray-600 text-white font-bold py-2 px-4 rounded hover:bg-gray-700"
                  : "bg-blue-600 text-white font-bold py-2 px-4 rounded hover:bg-blue-700"
              }
              disabled={addPayslip.isPending}
            >
              {addPayslip.isPending ? "Posting Payslip" : "Post Payslip"}
            </button>
            <button
              onClick={handlePayslip}
              className={
                addPayslip.isPending
                  ? "bg-gray-600 text-white font-bold py-2 px-4 rounded hover:bg-gray-700"
                  : "bg-blue-600 text-white font-bold py-2 px-4 rounded hover:bg-blue-700"
              }
              disabled={addPayslip.isPending}
            >
              {addPayslip.isPending ? "Generating Payslip" : "Generate Payslip"}
            </button>
          </div>
        </div>
      )}
      <AdaptiveModal
        isOpen={modal}
        onClose={() => setModal(false)}
        position={CustomModalPosition.Center}
        title={<p className="dark:text-white">Code</p>}
      >
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <div className="flex gap-4 mb-2 items-center">
              <label htmlFor="company_name" className="w-[40%]">
                Debit Salary Wages Expense
              </label>
              <input
                required
                type="text"
                name="debit_salary"
                value={debitSalary}
                onChange={(e) => setDebitSalary(e.target.value)}
                placeholder="Code"
                className="border rounded p-2"
              />
            </div>
          </div>
          <div className="mb-4">
            <div className="flex gap-4 mb-2 items-center">
              <label htmlFor="company_name" className="w-[40%]">
                Debit Social Insurance contributions
              </label>
              <input
                required
                type="text"
                name="debit_social"
                value={debitSocial}
                onChange={(e) => setDebitSocial(e.target.value)}
                placeholder="Code"
                className="border rounded p-2"
              />
            </div>
          </div>
          <div className="mb-4">
            <div className="flex gap-4 mb-2 items-center">
              <label htmlFor="company_name" className="w-[40%]">
                Credit Accrued Salary and Social Insurance Contributions
              </label>
              <input
                required
                type="text"
                name="credit"
                value={creditAccrued}
                onChange={(e) => setCreditAccrued(e.target.value)}
                placeholder="Code"
                className="border rounded p-2"
              />
            </div>
          </div>
          <button
            // onClick={() => postJournalMutation(postId)}
            onClick={handlePostMutation}
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
          >
            Post
          </button>
        </form>
      </AdaptiveModal>
    </div>
  );
};

export default PayrollPage;
