import React, { useState } from "react";
import LoadingPage from "../LoadingPage";
import FallbackPage from "../Fallback";
import GenericApi from "@/api/genericApi";
import { TransferQueryKey } from "@/config/constants/QueryKeys";
import { ApiRoutes } from "@/config/routes/ApiRoutes";
import { ApiError, ApiResponse } from "@/types/Api";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AdaptiveModal } from "@/components";
import { CustomModalPosition } from "@/types/Modal";
import CustomLogger from "@/utils/CustomLogger";
import FormField from "@/components/CustomForm/FormField";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { ValidationErrors, FormError } from "@/types/ValidationError";
import { AxiosError, isAxiosError } from "axios";
import { useSnackBarAlert } from "@/hooks/useSnackbar";
import {  generateTransfersInputs } from "@/utils/generateInputs";
import { useSelector } from "react-redux";
import { RootState } from "@/store";
import TransferItem from "@/components/Transfers/TransfersItem";
import {
  TransferCreateRequest,
  Transfer,
  TransferCreateSchema,
  TransferType,
} from "@/types/Banking/Transfer";

const Transfers: React.FC = () => {
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm<TransferCreateRequest>({
    resolver: zodResolver(TransferCreateSchema),
  });

  const queryClient = useQueryClient();
  const { showSnackBar } = useSnackBarAlert();
  const [transferModal, setTransferEntryModal] = useState(false);

  const [searchValue, setSearchValue] = useState<string | null>(null);
  const [openFilter, setOpenFilter] = useState(false);

  console.log(getValues());

  const {
    data: transferData,
    isLoading: isLoadingTransfer,
    error,
  } = useQuery<ApiResponse<Transfer[]>>({
    queryFn: async () => GenericApi.get<Transfer[]>(ApiRoutes.TRANSFERS),
    queryKey: [TransferQueryKey],
  });



//   const {
//     data: accounts,
//     isLoading: isLoadingAccounts,
//     error: errorAccounts,
// } = useQuery<ApiResponse<Account[]>>({
//   queryFn: async () => GenericApi.get<Account[]>(ApiRoutes.ACCOUNTS),
//     queryKey: [AccountQueryKey],
//   })

//   const {
//     data: transactions,
//     isLoading: isLoadingTransactions,
//     error: errorTransactions,
//   } = useQuery<ApiResponse<Transaction[]>>({
//     queryFn: async () => GenericApi.get<Transaction[]>(ApiRoutes.TRANSACTIONS),
//     queryKey: [TransactionsQueryKey],
//     enabled: !!accounts?.data,
//   })




  const transfer_inputs = generateTransfersInputs();

  const { selectedCompany } = useSelector((state: RootState) => state.company);

  const addTransferMutation = useMutation<
    ApiResponse<TransferType>,
    ApiError<ValidationErrors>,
    TransferCreateRequest
  >({
    mutationFn: (data) => GenericApi.post<TransferType>(ApiRoutes.TRANSFERS, data),
    onSuccess: async ({ data }) => {
      CustomLogger.log(data);
      reset();
      setTransferEntryModal(false);
      queryClient.invalidateQueries({ queryKey: [TransferQueryKey] });
      showSnackBar(
        "Transfer created",
        "success",
        { vertical: "top", horizontal: "right" },
        3000
      );
    },
    onError: async ({ error }) => {
      CustomLogger.error(error);
      handleAxiosError(error);
      showSnackBar(
        "Error creating a Transfer",
        "error",
        { vertical: "top", horizontal: "right" },
        3000
      );
    },
  });

  const handleAxiosError = (error: AxiosError<unknown> | null | Error) => {
    if (isAxiosError<FormError>(error)) {
      console.log(error);
      return;
    }

    if (isAxiosError<ValidationErrors>(error)) {
      console.log(error);
      return;
    }

    console.log(error);
  };

  const onSubmit = (data: TransferCreateRequest) => {
    if (!selectedCompany) {
      showSnackBar(
        "Please select a company",
        "error",
        { vertical: "top", horizontal: "right" },
        3000
      );
      return;
    }


    const payload = {
      ...data,
      company_id: selectedCompany?.id,
      // from_account_id: data.from_account,
      // to_account_id: data.to_account,

    };

    CustomLogger.log(data);
    addTransferMutation.mutate(payload);
  };



  const renderOrganizationBySearchValue = () => {
    if (searchValue) {
      const filteredTransfer = transferData?.data?.filter(
        (transfer) => transfer.amount
      );
      return filteredTransfer?.map((transfer) => (
        <TransferItem transfer={transfer} key={transfer.id} />
      ));
    }

    return transferData?.data?.map((transfer) => (
      <TransferItem transfer={transfer} key={transfer.id} />
    ));
  };


  const toggleCompanyModal = () => {
    setTransferEntryModal(!transferModal);
  };

  const toggleFilter = () => {
    setOpenFilter(!openFilter);
  };

  const onRetry = () => {
    window.location.reload();
  };

  if (isLoadingTransfer)
    return (
      <div className="mt-[4rem]">
        <LoadingPage />
      </div>
    );

  const fetchError = error;

  if (fetchError) {
    console.error(fetchError);
    return <FallbackPage onRetry={onRetry} />;
  }

  if (!transferData?.data) {
    console.error("Data is undefined");
    return <FallbackPage onRetry={onRetry} />;
  }

  return (
    <div className="bg-gray-50 mt-[4rem] dark:bg-gray-900">
      <div className="bg-white dark:bg-gray-800 relative shadow-md sm:rounded-lg">
        <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-4">
          <div className="w-full md:w-full flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-between md:space-x-3">
            <div className="flex justify-center items-center gap-4">
              <form className="flex items-center">
                <label htmlFor="search" className="sr-only">
                  Search
                </label>
                <div className="relative w-full">
                  <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                    <svg
                      className="w-4 h-4 text-gray-800 dark:text-white"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeWidth="2"
                        d="m21 21-3.5-3.5M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z"
                      />
                    </svg>
                  </div>
                  <input
                    onChange={(e) => setSearchValue(e.target.value)}
                    type="search"
                    id="default-search"
                    className="block w-full ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Search"
                    required
                  />
                </div>
              </form>
              <button
                onClick={toggleFilter}
                id="filterDropdownButton"
                data-dropdown-toggle="filterDropdown"
                className="flex relative items-center justify-center w-full px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg md:w-auto focus:outline-none hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                type="button"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  className="w-4 h-4 mr-2 text-gray-400"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z"
                    clipRule="evenodd"
                  />
                </svg>
                Filter
                <svg
                  className="-mr-1 ml-1.5 w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                >
                  <path
                    clipRule="evenodd"
                    fillRule="evenodd"
                    d="M5.293 7.707a1 1 0 001.414 0L10 4.414l3.293 3.293a1 1 0 001.414-1.414l-4-4a1 1 0 00-1.414 0l-4 4a1 1 0 000 1.414z"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div className="flex justify-end items-center w-full">
            <button
              onClick={toggleCompanyModal}
              id="createTransferButton"
              className="flex items-center justify-center text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 focus:outline-none dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
              type="button"
            >
              <svg
                className="w-3 h-3 mr-2 text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 18 18"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 1v16M1 9h16"
                />
              </svg>
              Add Transfer
            </button>
            <AdaptiveModal
              isOpen={transferModal}
              onClose={() => setTransferEntryModal(false)}
              position={CustomModalPosition.Center}
              title={<p className="dark:text-white">Add Transfer</p>}
            >
              <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
                {transfer_inputs.map((input) => (
                  <FormField<TransferCreateRequest>
                    key={input.name}
                    type={input.type}
                    placeholder={input.placeholder}
                    name={input.name}
                    required={input.required}
                    valueAsNumber={input.valueAsNumber}
                    inputStyle="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    label={input.label}
                    options={input.options}
                    register={register}
                    error={errors[input.name as keyof TransferCreateRequest]}
                  />
                ))}
                <button
                  type="submit"
                  className="flex w-full items-center justify-center text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
                >
                  {addTransferMutation.isPending ? (
                    <svg
                      className="w-5 h-5 mr-3 border-r-2 border-white rounded-full animate-spin"
                      viewBox="0 0 24 24"
                    ></svg>
                  ) : (
                    "Create Transfer"
                  )}
                </button>
              </form>
            </AdaptiveModal>
          </div>
        </div>
        <div className="overflow-x-auto">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs w-full text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 space-x-4">
                <th scope="col" className="px-4 py-3 text-left">
                  Transfered At
                </th>
                <th scope="col" className="px-4 py-3 text-center">
                  From Account
                </th>
                <th scope="col" className="px-4 py-3 text-center">
                  To Account
                </th>
                <th scope="col" className="px-4 py-3 text-center">
                  Amount
                </th>
                <th scope="col" className="px-4 py-3 text-right">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>{renderOrganizationBySearchValue()}</tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Transfers;
