import React, { Fragment, useState } from 'react'
import GenericApi from '@/api/genericApi'
import { ItemsQueryKey } from '@/config/constants/QueryKeys'
import { ApiResponse, ApiError } from '@/types/Api'
import { FormError, ValidationErrors } from '@/types/ValidationError'
import CustomLogger from '@/utils/CustomLogger'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useSnackBarAlert } from '@/hooks/useSnackbar'
import { AxiosError, isAxiosError } from 'axios'
import { AdaptiveModal, LoadingSpinner } from '..'
import { CustomModalPosition } from '@/types/Modal'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import FormField from '../CustomForm/FormField'
import { Item, ItemType, ItemUpdate, ItemUpdateSchema } from '@/types/Items'
import { generateItemUpdateInputs } from '@/utils/generateInputs'
import { RootState } from '@/store'
import { useSelector } from 'react-redux'



export interface ItemsItemProps {  //modificat ***
    items: ItemType
}


const ItemsItem: React.FC<ItemsItemProps> = ({ items }) => {  //modificat ***

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<ItemUpdate>({ 
        resolver: zodResolver(ItemUpdateSchema), 
    });

    const { selectedCompany } = useSelector((state: RootState) => state.company);

    console.log({
        errors
    })

    const queryClient = useQueryClient()
    const { showSnackBar } = useSnackBarAlert()
    const [deleteModalOpen, setDeleteModalOpen] = useState(false)
    const [editModalOpen, setEditModalOpen] = useState(false)

    const deleteItemsMutation = useMutation<ApiResponse<{ message: string }>, ApiError<ValidationErrors>, { id: number }>( //modificat ***
        {
            mutationFn: (data) => GenericApi.delete<{ message: string }>(`/invoice-saved-items/${selectedCompany?.id}/${data.id}`), //modificat ***
            onSuccess: async ({ data }) => {
                CustomLogger.log(data)
                queryClient.invalidateQueries({ queryKey: [ItemsQueryKey] }) //modificat ***
                showSnackBar("Item deleted", 'success', { vertical: 'top', horizontal: 'right' }, 3000) //modificat ***
            },
            onError: async ({ error }) => {
                CustomLogger.error(error)
                handleAxiosError(error)
                showSnackBar("Error deleting Item", 'error', { vertical: 'top', horizontal: 'right' }, 3000) //modificat ***
            }
        }
    )

    const handleAxiosError = (error: AxiosError<unknown> | null | Error) => {
        if (isAxiosError<FormError>(error)) {
            console.log(error)
            return
        }

        if (isAxiosError<ValidationErrors>(error)) {
            console.log(error)
            return
        }

        console.log(error)
    }

    const handleDelete = (id: number) => {
        deleteItemsMutation.mutate({ id }) //modificat ***
    }

    const toggleDeleteModal = () => {
        setDeleteModalOpen(!deleteModalOpen)
    }

    const toggleEditModal = () => {
        setEditModalOpen(!editModalOpen)
    }

    const updateItemsMutation = useMutation<ApiResponse<Item>, ApiError<ValidationErrors>, ItemUpdate>( //modificat ***
        {
            mutationFn: (data) => GenericApi.put<Item>(`${`/invoice-saved-items/${selectedCompany?.id}`}/${items.id}`, data), //modificat ***
            onSuccess: async ({ data }) => {
                CustomLogger.log(data)
                setEditModalOpen(false)
                queryClient.invalidateQueries({ queryKey: [ItemsQueryKey] }) //modificat ***
                showSnackBar("Item updated", 'success', { vertical: 'top', horizontal: 'right' }, 3000) //modificat ***
            },
            onError: async ({ error }) => {
                CustomLogger.error(error)
                handleAxiosError(error)
                showSnackBar("Error updating Item", 'error', { vertical: 'top', horizontal: 'right' }, 3000) //modificat ***
            }
        }
    )

    const updateItemsInputs = generateItemUpdateInputs(items) //modificat ***

    const onSubmit = (data: ItemUpdate) => { //modificat ***
        
        const payload = {
            ...data,
        }
        
        updateItemsMutation.mutate(payload)
    }

    return (
        <Fragment>
            <tr
                className="border-b dark:border-gray-700 hover:bg-gray-100 dark:hover:bg-gray-700"
            >
                
                <td className="px-4 py-3 text-left">{items.type}</td>
                <td className="px-4 py-3 text-center">{items?.name}</td>
                <td className="px-4 py-3 text-center">{items.sale_price}</td>
                <td className="px-4 py-3 text-right">
                    <button
                        onClick={toggleEditModal}
                        className="font-medium text-green-400 dark:text-green-400 hover:underline"
                    >
                        Edit
                    </button>
                    <span className="mx-2">|</span>
                    <button
                        onClick={toggleDeleteModal}
                        className="font-medium text-red-600 dark:text-red-500 hover:underline"
                    >
                        Delete
                    </button>

                </td>
            </tr>


            <AdaptiveModal
                isOpen={deleteModalOpen}
                onClose={() => setDeleteModalOpen(false)}
                title={<h2 className="text-lg font-semibold text-gray-900 dark:text-white">Delete Item</h2>}
                position={CustomModalPosition.Center}
                footer={
                    <Fragment>
                        <button
                            onClick={() => handleDelete(items.id)}
                            className="bg-red-600 text-white px-3 py-1.5 rounded-lg hover:bg-red-700"
                        >
                            {
                                deleteItemsMutation.isPending ? <LoadingSpinner variant='danger' /> : 'Delete'
                            }
                        </button>
                        <button
                            onClick={toggleDeleteModal}
                            className="bg-gray-200 text-gray-800 px-3 py-1.5 rounded-lg hover:bg-gray-300"
                        >
                            Cancel
                        </button>
                    </Fragment>
                }
            >
                <p className="text-gray-700 dark:text-gray-300">
                    Are you sure you want to delete this item?
                </p>
            </AdaptiveModal>


            <AdaptiveModal
                isOpen={editModalOpen}
                onClose={() => setEditModalOpen(false)}
                title={<h2 className="text-lg font-semibold text-gray-900 dark:text-white">Edit Item</h2>}
                position={CustomModalPosition.Center}
            >
                <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
                    {
                        updateItemsInputs.map(input => (
                            <FormField<ItemUpdate> 
                                key={input.name}
                                type={input.type}
                                placeholder={input.placeholder}
                                name={input.name}
                                required={input.required}
                                valueAsNumber={input.valueAsNumber}
                                inputStyle='mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500'
                                label={input.label}
                                options={input.options}
                                defaultValue={input?.defaultValue ?? ''}
                                register={register}
                                error={errors[input.name as keyof ItemUpdate]} //modificat ***
                            />
                        ))
                    }
                    <button type="submit" className="flex w-full items-center justify-center text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800">
                        {
                            updateItemsMutation.isPending ? <svg className="w-5 h-5 mr-3 border-r-2 border-white rounded-full animate-spin" viewBox="0 0 24 24"></svg> : "Edit Item"
                         //modificat ***
                        }

                    </button>
                </form>
            </AdaptiveModal>
        </Fragment>
    )
}

export default ItemsItem;
