import { Footer, Header, Sidebar, TopSidebar } from '@/components'
import { RoutesPathList } from '@/config/routes/Routes'
import React, { Fragment } from 'react'
import { useLocation } from 'react-router-dom'

export interface LayoutProps {
    children: React.ReactNode
}

const Layout: React.FC<LayoutProps> = ({ children }) => {

    const location = useLocation()
    const routesWithoutHeader = [RoutesPathList.ActivationSuccess, RoutesPathList.Login, RoutesPathList.Register, RoutesPathList.OrganizationDashboard, RoutesPathList.OrganizationUserProfile, RoutesPathList.OrganizationUserPassword, RoutesPathList.ResetPassword, RoutesPathList.ResetPasswordEmail, RoutesPathList.CompanyDasboard, RoutesPathList.NotFound, RoutesPathList.Billing, RoutesPathList.Permissions, RoutesPathList.CompanyUsers, RoutesPathList.CreateCompanyUser, RoutesPathList.DoubleEntry, RoutesPathList.Items,RoutesPathList.DoubleEntry,RoutesPathList.Accounts, RoutesPathList.Taxes, RoutesPathList.Contacts, RoutesPathList.Departments, RoutesPathList.ExchangeRates, RoutesPathList.Categories,RoutesPathList.Employees,RoutesPathList.Transfers,RoutesPathList.Reconciliations, RoutesPathList.Transactions, RoutesPathList.Documents,RoutesPathList.Journals, RoutesPathList.Pages, RoutesPathList.Reports, RoutesPathList.Salary, RoutesPathList.Invoice, RoutesPathList.Client, RoutesPathList.PayrollPage, RoutesPathList.PaymentResponse, RoutesPathList.PaymentFailResponse]
    const routesWithoutFooter = [RoutesPathList.ActivationSuccess, RoutesPathList.Login, RoutesPathList.Register, RoutesPathList.OrganizationDashboard, RoutesPathList.OrganizationUserProfile, RoutesPathList.OrganizationUserPassword, RoutesPathList.ResetPassword, RoutesPathList.ResetPasswordEmail, RoutesPathList.CompanyDasboard, RoutesPathList.NotFound, RoutesPathList.Billing, RoutesPathList.Permissions, RoutesPathList.CompanyUsers, RoutesPathList.CreateCompanyUser, RoutesPathList.DoubleEntry, RoutesPathList.Items,RoutesPathList.DoubleEntry,RoutesPathList.Accounts, RoutesPathList.Taxes, RoutesPathList.Contacts, RoutesPathList.Departments, RoutesPathList.ExchangeRates, RoutesPathList.Categories,RoutesPathList.Employees,RoutesPathList.Transfers,RoutesPathList.Reconciliations, RoutesPathList.Transactions, RoutesPathList.Documents,RoutesPathList.Journals, RoutesPathList.Pages, RoutesPathList.Reports, RoutesPathList.Salary, RoutesPathList.Invoice, RoutesPathList.Client, RoutesPathList.PayrollPage, RoutesPathList.PaymentResponse, RoutesPathList.PaymentFailResponse]
    const routesWithoutSidebar = [RoutesPathList.ActivationSuccess, RoutesPathList.Login, RoutesPathList.Register, RoutesPathList.Landing, RoutesPathList.About, RoutesPathList.Contact, RoutesPathList.Payroll, RoutesPathList.Accounting, RoutesPathList.Invoicing, RoutesPathList.ResetPassword, RoutesPathList.ResetPasswordEmail, RoutesPathList.NotFound, RoutesPathList.CreateCompanyUser, RoutesPathList.SuccessPay]

    const isHeaderVisible = !routesWithoutHeader.includes(location.pathname as RoutesPathList) && !location.pathname.includes(RoutesPathList.ResetPassword)
    const isFooterVisible = !routesWithoutFooter.includes(location.pathname as RoutesPathList) && !location.pathname.includes(RoutesPathList.ResetPassword)
    const isTopSidebarVisible = !routesWithoutSidebar.includes(location.pathname as RoutesPathList) && !location.pathname.includes(RoutesPathList.ResetPassword)

    console.log({
        l: location.pathname.includes(RoutesPathList.ResetPassword)
    })

    const [isCollapsable, setIsCollapsable] = React.useState<boolean>(false)

    const handleCollapse = () => {
        setIsCollapsable(!isCollapsable)
    }

    return (
        <Fragment>
            {isHeaderVisible ? <Header /> : null}
            {isTopSidebarVisible ? <TopSidebar /> : null}

            {
                isTopSidebarVisible ?
                    <div className="flex w-full flex-row justify-start">
                        <Fragment>
                            {
                                <Sidebar children={null} handleCollapse={handleCollapse} isCollapsable={isCollapsable} isOpen onClose={() => { }} />
                            }
                            {/* dark:bg-gray-900 */}
                            <div className={`${
                                isCollapsable ? 'ml-20' : 'ml-64'
                            } w-full p-3 dark:bg-gray-900 min-h-[100dvh]`}>
                                {children}
                            </div>
                        </Fragment>
                    </div> : children
            }

            {isFooterVisible ? <Footer /> : null}
        </Fragment >
    )
}

export default Layout