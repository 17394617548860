import { z, ZodSchema } from "zod";

export type Item = {
    id: number;
    company: number;
    type: "product" | "service";
    name: string;
    description?: string;
    sale_price: string;  
    income_account?: number | null;  
    expense_account?: number | null;  
}

export type ItemCreateRequest = {
    type: "product" | "service";
    name: string;
    description?: string;
    sale_price: string;  
    income_account?: number | null;  
    expense_account?: number | null;  
}

export type ItemUpdate = {
    type: "product" | "service";
    name: string;
    description?: string;
    sale_price: string;  
}

export const ItemCreateSchema: ZodSchema<ItemCreateRequest> = z.object({
    type: z.enum(["product", "service"]),
    name: z.string().min(1),
    description: z.string().optional(),
    sale_price: z.string(),
  });

  export const ItemUpdateSchema: ZodSchema<ItemUpdate> = z.object({
    type: z.enum(["product", "service"]),
    name: z.string().min(1),
    description: z.string().optional(),
    sale_price: z.string(),
  });
  
  

  export type ItemType = {
    id: number;
    type: string;
    name: string;
    description: string;
    sale_price: string;
    company: number;
};