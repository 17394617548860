import {PricingCard} from '@/components'
import React from 'react'
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {ApiError, ApiResponse} from "@/types/Api.tsx";
import GenericApi from "@/api/genericApi.tsx";
import {ApiRoutes, composeIdRoute} from "@/config/routes/ApiRoutes.tsx";
import {PaymentQueryKey, PricingPlansQueryKey, UserPayQueryKey} from "@/config/constants/QueryKeys.tsx";
import FallbackPage from "../Fallback.tsx";
import LoadingPage from "../LoadingPage.tsx";
import {InitiatePayment, Payment, PricingPlan} from "@/types/Payment.tsx";
import {ValidationErrors} from "@/types/ValidationError.tsx";
import CustomLogger from "@/utils/CustomLogger.tsx";
import {useSnackBarAlert} from "@/hooks/useSnackbar.tsx";
import {User} from "@/types/User.tsx";
import {getData, getUser, saveData} from "@/utils/localStorage.tsx";
import {AuthResponse} from "@/types/Auth.tsx";
import {authStorageUser} from "@/config/constants/StorageKeys.tsx";

const Billing: React.FC = () => {

    const { showSnackBar } = useSnackBarAlert();
    const queryClient = useQueryClient();
    const user = getUser()

    const route = composeIdRoute(ApiRoutes.USERS, user?.id ?? null);

    const {
        data: currentUser,
    } = useQuery<ApiResponse<User>>({
        queryFn: async () =>
            GenericApi.get<User>(route, undefined, undefined, (data) => {
                const userData = getData<AuthResponse>(authStorageUser);
                if(!userData) return
                saveData(authStorageUser, {
                    ...userData,
                    user: data
                });
            }),
        queryKey: [UserPayQueryKey],
    });

    console.log(currentUser)

    const {
        data: pricingPlans,
        isLoading,
        error,
    } = useQuery<ApiResponse<PricingPlan[]>>({
        queryFn: async () =>
            GenericApi.get<PricingPlan[]>(ApiRoutes.PRICING),
        queryKey: [PricingPlansQueryKey],
    });

    const {
        data: billingHistory,
        isLoading: billingHistoryLoading,
    } = useQuery<ApiResponse<Payment[]>>({
        queryFn: async () =>
            GenericApi.get<Payment[]>(ApiRoutes.PAYMENT),
        queryKey: [PaymentQueryKey],
    });

    const initiatePaymentMutation = useMutation<ApiResponse<{
        payment_link: string;
    }>, ApiError<ValidationErrors>, InitiatePayment>(
        {
            mutationFn: (data) => GenericApi.post<{payment_link: string;}>(ApiRoutes.INITIATE_PAYMENT, {
                ...data,
            }),
            onSuccess: async ({ data }) => {
                CustomLogger.log(data)
                showSnackBar("Payment request created", 'success', { vertical: 'top', horizontal: 'right' }, 3000)
                if(data?.payment_link){
                    window.location.href = data.payment_link;
                }else{
                    showSnackBar("Error creating payment", 'error', { vertical: 'top', horizontal: 'right' }, 3000)
                }
            },
            onError: async ({ error }) => {
                CustomLogger.error(error)
                showSnackBar("Error creating payment", 'error', { vertical: 'top', horizontal: 'right' }, 3000)
            }
        }
    )

    const initiatePaymentCreditsMutation = useMutation<ApiResponse<{
        payment_link: string;
    }>, ApiError<ValidationErrors>, null>(
        {
            mutationFn: () => GenericApi.post<{payment_link: string;}>(ApiRoutes.INITIATE_CREDIT_PAYMENT, {}),
            onSuccess: async ({ data }) => {
                CustomLogger.log(data)
                showSnackBar("Payment request created", 'success', { vertical: 'top', horizontal: 'right' }, 3000)
                if(data?.payment_link){
                    window.location.href = data.payment_link;
                }else{
                    showSnackBar("Error creating payment", 'error', { vertical: 'top', horizontal: 'right' }, 3000)
                }
                queryClient.invalidateQueries({queryKey: [UserPayQueryKey]})
            },
            onError: async ({ error }) => {
                CustomLogger.error(error)
                showSnackBar("Error creating payment", 'error', { vertical: 'top', horizontal: 'right' }, 3000)
            }
        }
    )

    const handleBuyCredits = () => {
        initiatePaymentCreditsMutation.mutate(null);
    }

    const fetchError = error || pricingPlans?.error || billingHistory?.error;

    const onRetry = () => {
        window.location.reload();
    };

    if (isLoading || billingHistoryLoading) {
        return (
            <div className="mt-[4rem]">
                <LoadingPage />
            </div>
        );
    }

    if (fetchError) {
        console.error(fetchError);
        return <FallbackPage onRetry={onRetry} />;
    }

    if (!pricingPlans?.data) {
        console.error("Data is undefined");
        return <FallbackPage onRetry={onRetry} />;
    }


    return (
        <>
        <div className="bg-white mt-[4rem] dark:bg-gray-900">
            <div className="mx-4 max-w-screen-xl sm:mx-8 xl:mx-auto">
                <div className="w-full pt-3 pb-10 sm:grid-cols-10">
                    <div
                        className="col-span-8 rounded-x sm:px-8 sm:shadow rounded-lg pb-6 dark:bg-gray-800 dark:text-gray-200">
                        {/*<div className="pt-4">*/}
                        {/*    <h1 className="py-2 text-2xl font-semibold">Billing settings</h1>*/}
                        {/*    <p className="text-slate-600 dark:text-gray-400">*/}
                        {/*        Manage your billing details, payment methods, and billing history.*/}
                        {/*    </p>*/}
                        {/*</div>*/}
                        {/*<hr className="mt-4 mb-8 border-gray-200 dark:border-gray-700"/>*/}

                        {/*<div className="mb-10 grid gap-y-8 lg:grid-cols-2 lg:gap-y-0">*/}
                        {/*    <div className="space-y-8">*/}
                        {/*        <div className="">*/}
                        {/*            <div className="flex">*/}
                        {/*                <p className="font-medium mb-1">Billing Period</p>*/}
                        {/*                <button*/}
                        {/*                    className="ml-auto inline-flex text-sm font-semibold text-blue-600 underline decoration-2 dark:text-blue-500">Change*/}
                        {/*                </button>*/}
                        {/*            </div>*/}
                        {/*            <div*/}
                        {/*                className="flex items-center rounded-md border border-gray-100 bg-white dark:bg-gray-800 dark:border-gray-700 dark:text-gray-200 py-3 shadow">*/}
                        {/*                <p className="ml-4 w-56">*/}
                        {/*                    <strong className="block text-lg font-medium">MONTHLY</strong>*/}
                        {/*                    <span className="text-xs text-gray-400 dark:text-gray-500"> Next Renewal: 4 Jan 2022 </span>*/}
                        {/*                </p>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*        <div className="">*/}
                        {/*            <div className="flex">*/}
                        {/*                <p className="font-medium mb-1">Payment Method</p>*/}
                        {/*                <button*/}
                        {/*                    className="ml-auto inline-flex text-sm font-semibold text-blue-600 underline decoration-2 dark:text-blue-500">Change*/}
                        {/*                </button>*/}
                        {/*            </div>*/}
                        {/*            <div*/}
                        {/*                className="flex items-center rounded-md border border-gray-100 bg-white dark:bg-gray-800 dark:border-gray-700 dark:text-gray-200 py-3 shadow">*/}
                        {/*                <CreditCard/>*/}
                        {/*                <p className="ml-4 w-56">*/}
                        {/*                    <strong className="block text-lg font-medium">**** **** **** 453 </strong>*/}
                        {/*                    <strong className="block text-lg font-medium">YOUR NAME</strong>*/}
                        {/*                    <span className="text-xs text-gray-400 dark:text-gray-500"> Expires on: Dec 2024 </span>*/}
                        {/*                </p>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}

                        {/*    <div className="grid gap-y-6 gap-x-3 sm:grid-cols-2 lg:px-8">*/}
                        {/*        <label className="block" htmlFor="name">*/}
                        {/*            <p className="text-sm">Name</p>*/}
                        {/*            <input*/}
                        {/*                className="w-full rounded-md border bg-white dark:bg-gray-800 dark:text-gray-200 dark:border-gray-700 py-2 px-2 outline-none ring-blue-600 focus:ring-1"*/}
                        {/*                type="text" value="Shakir Ali"/>*/}
                        {/*        </label>*/}
                        {/*        <label className="block" htmlFor="email">*/}
                        {/*            <p className="text-sm">Email Address</p>*/}
                        {/*            <input*/}
                        {/*                className="w-full rounded-md border bg-white dark:bg-gray-800 dark:text-gray-200 dark:border-gray-700 py-2 px-2 outline-none ring-blue-600 focus:ring-1"*/}
                        {/*                type="text" value="shakir.ali@corpora.de"/>*/}
                        {/*        </label>*/}
                        {/*        <label className="block sm:col-span-2" htmlFor="billing-address">*/}
                        {/*            <p className="text-sm">Billing Address</p>*/}
                        {/*            <input*/}
                        {/*                className="w-full rounded-md border bg-white dark:bg-gray-800 dark:text-gray-200 dark:border-gray-700 py-2 px-2 outline-none ring-blue-600 focus:ring-1"*/}
                        {/*                type="text" value="82844 Boyle Extension Suite 541 - Covington, HI / 28013"/>*/}
                        {/*        </label>*/}
                        {/*        <label className="block" htmlFor="vat">*/}
                        {/*            <p className="text-sm">VAT #</p>*/}
                        {/*            <input*/}
                        {/*                className="w-full rounded-md border bg-white dark:bg-gray-800 dark:text-gray-200 dark:border-gray-700 py-2 px-2 outline-none ring-blue-600 focus:ring-1"*/}
                        {/*                type="text" value="6346322"/>*/}
                        {/*        </label>*/}
                        {/*        <label className="block" htmlFor="country">*/}
                        {/*            <p className="text-sm">Country</p>*/}
                        {/*            <input*/}
                        {/*                className="w-full rounded-md border bg-white dark:bg-gray-800 dark:text-gray-200 dark:border-gray-700 py-2 px-2 outline-none ring-blue-600 focus:ring-1"*/}
                        {/*                type="text" value="Germany"/>*/}
                        {/*        </label>*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                        <h1 className="py-2 text-2xl font-semibold">Pricing Plans</h1>
                        <p className="text-slate-600 dark:text-gray-400">
                            Choose a plan that suits your needs.
                        </p>
                        <hr className="mt-4 mb-8 border-gray-200 dark:border-gray-700"/>

                        <div className='flex justify-center gap-16 mb-5 '>
                            {
                                pricingPlans?.data.map((plan) => (
                                    <PricingCard
                                        id={plan.id}
                                        key={plan.id}
                                        title={plan.name}
                                        price={plan.price}
                                        companies={plan.companies}
                                        users={plan.users}
                                        description={plan.description}
                                        isCurrentPlan={
                                            user?.current_plan?.id === plan.id
                                        }
                                        onStartNow={(id: number) => {
                                            console.log("Start now");
                                            initiatePaymentMutation.mutate({plan_id: id});
                                        }}
                                        isLoading={initiatePaymentMutation.isPending}
                                    />
                                ))
                            }
                        </div>

                        <h1 className="py-2 text-2xl font-semibold">Credits</h1>
                        <p className="text-slate-600 dark:text-gray-400">
                            Buy Credits for Crypto Currency Reports.
                        </p>
                        <hr className="mt-4 mb-8 border-gray-200 dark:border-gray-700"/>

                        <div className='flex justify-between items-center'>
                            <div className='flex flex-col justify-start'>
                                <p>
                                    Available Credits:
                                </p>

                                <p>
                                    {user?.organization_crypto_credits ?? 0}
                                </p>
                            </div>

                            <button disabled={initiatePaymentCreditsMutation.isPending} onClick={handleBuyCredits} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                                {
                                    initiatePaymentCreditsMutation.isPending ? 'Processing...' : 'Buy 10000 Credits'
                                }
                            </button>
                        </div>

                        <hr className="mt-4 mb-8 border-gray-200 dark:border-gray-700"/>

                        <h1 className="py-2 text-2xl font-semibold">Billing history</h1>
                        <p className="text-slate-600 dark:text-gray-400">
                            View and download your invoices.
                        </p>
                        <hr className="mt-4 mb-8 border-gray-200 dark:border-gray-700"/>
                        <div
                            className="amx-auto mb-10 overflow-hidden rounded-lg border bg-white dark:bg-gray-800 dark:border-gray-700">
                            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                <thead
                                    className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                <tr>
                                    <th scope="col" className="px-4 py-3 text-center">
                                        Date
                                    </th>
                                    <th scope="col" className="px-4 py-3 text-center">
                                        Status
                                    </th>
                                    <th scope="col" className="px-4 py-3 text-center">
                                        Amount
                                    </th>

                                </tr>
                                </thead>
                                <tbody>
                                {
                                    billingHistory?.data?.map((payment) => (
                                        <tr key={payment.id}
                                            className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                            <td className="px-4 py-3 text-center">{new Date(payment?.created_at ?? new Date()).toISOString().split("T")[0]}</td>
                                            <td className="px-4 py-3 text-center">{payment.status}</td>
                                            <td className="px-4 py-3 text-center">{payment.amount}</td>

                                        </tr>
                                    ))
                                }
                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default Billing
