
export type Permission = {
    [key: string]: boolean | number;
    id: number;
    read_reports: boolean;
    edit_accounting_and_payroll: boolean;
    read_invoicing: boolean;
    edit_invoicing: boolean;
}

export enum PermissionName {
    READ_REPORTS = 'read_reports',
    EDIT_ACCOUNTING_AND_PAYROLL = 'edit_accounting_and_payroll',
    READ_INVOICING = 'read_invoicing',
    EDIT_INVOICING = 'edit_invoicing',
}


type CompanyPayload = {
        company_id: number;
        edit_accounting_and_payroll: boolean;
        edit_invoicing: boolean;
        read_invoicing: boolean;
        read_reports: boolean;
}


export type PermissionRequest = {
    user: number;
    permissions: CompanyPayload[];
    email: string;
}



