import GenericApi from "@/api/genericApi";
import { InvoiceQueryKey } from "@/config/constants/QueryKeys";
import { useSnackBarAlert } from "@/hooks/useSnackbar";
import { RootState } from "@/store";
import { ApiResponse, ApiError } from "@/types/Api";
import { ValidationErrors } from "@/types/ValidationError";
import CustomLogger from "@/utils/CustomLogger";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { isAxiosError } from "axios";
import { useState } from "react";
import { useSelector } from "react-redux";

type Client = {
  id: number;
  name: string;
  email: string;
  country: string;
  address: string;
  telephone: string;
  vat_number: string;
  code: string;
};

type ClientCreate = {
  company_id?: number;
  name: string;
  email: string;
  country: string;
  address: string;
  telephone: string;
  vat_number: string;
  code: string;
};

type ClientUpdate = {
  company_id?: number;
  name: string;
  email: string;
  country: string;
  address: string;
  id: number;
  telephone: string;
  vat_number: string;
  code: string;
};

interface ChartAccountClientResponse {
  client: Client;
  id: number;
  company: number;
  code: string;
  type: string;
  name: string;
  subcategory: string;
}

const ClientForm = () => {
  const queryClient = useQueryClient();
  const { showSnackBar } = useSnackBarAlert();
  const selectedCompany = useSelector(
    (state: RootState) => state.company.selectedCompany
  );

  const [client, setClient] = useState<ClientCreate>({
    name: "",
    country: "",
    email: "",
    address: "",
    vat_number: "",
    telephone: "",
    code: "",
  });

  const [isEditClient, setIsEditClient] = useState(-1);

  const clientRoute = `/invoice-client/${selectedCompany?.id}`;

  const { data: clientData } = useQuery<ApiResponse<ChartAccountClientResponse[]>>({
    queryKey: ["ClientQueryKey"],
    queryFn: async () => GenericApi.get<ChartAccountClientResponse[]>(clientRoute),
  });

  console.log(clientData?.data?.[0], "clientData");

  const addClientMutation = useMutation<
    ApiResponse<Client>,
    ApiError<ValidationErrors>,
    ClientCreate
  >({
    mutationFn: (data) => GenericApi.post<Client>(clientRoute, data),
    onSuccess: async ({ data }) => {
      CustomLogger.log(data);
      queryClient.invalidateQueries({ queryKey: ["ClientQueryKey"] });
      showSnackBar(
        "Client created",
        "success",
        { vertical: "top", horizontal: "right" },
        3000
      );
      resetForm();
    },
    onError: ({ error }) => {
      CustomLogger.error(error);
      if(isAxiosError(error)){
        if(error?.status === 403){
          showSnackBar(
            "Not Authorized",
            "error",
            { vertical: "top", horizontal: "right" },
            3000
          );
        }else{
          showSnackBar(
            "Error creating Client",
            "error",
            { vertical: "top", horizontal: "right" },
            3000
          );
        }
      }
      showSnackBar(
        "Error creating Client",
        "error",
        { vertical: "top", horizontal: "right" },
        3000
      );
    },
  });

  // const addDoubleEntryAccountMutation = useMutation<
  //   ApiResponse<ChartOfAccount>,
  //   ApiError<ValidationErrors>,
  //   ChartOfAccount
  // >({
  //   mutationFn: (data) =>
  //     GenericApi.post<DoubleEntryAccount>(ApiRoutes.DOUBLE_ENTRY, data),
  //   onSuccess: async ({ data }) => {
  //     CustomLogger.log(data);
  //     queryClient.invalidateQueries({ queryKey: [DoubleEntryAccountQueryKey] });
  //     showSnackBar(
  //       "DoubleEntryAccount created",
  //       "success",
  //       { vertical: "top", horizontal: "right" },
  //       3000
  //     );
  //   },
  //   onError: async ({ error }) => {
  //     CustomLogger.error(error);
  //     showSnackBar(
  //       "Error creating DoubleEntryAccount",
  //       "error",
  //       { vertical: "top", horizontal: "right" },
  //       3000
  //     );
  //   },
  // });

  const resetForm = () => {
    setClient({
      name: "",
      country: "",
      address: "",
      email: "",
      vat_number: "",
      telephone: "",
      code: "",
    });
  };

  const handleInputChange = (field: keyof Client, value: string) => {
    setClient((prevClient) => ({
      ...prevClient,
      [field]: value,
    }));
  };

  const deleteRoute = `invoice-client/${selectedCompany?.id}`;

  const clientDelete = useMutation<
    ApiResponse<{ message: string }>,
    ApiError<ValidationErrors>,
    { id: number }
  >({
    mutationFn: (data) =>
      GenericApi.delete<{ message: string }>(`/${deleteRoute}/${data.id}`),
    onSuccess: async ({ data }) => {
      CustomLogger.log(data);
      queryClient.invalidateQueries({ queryKey: ["ClientQueryKey"] });
      showSnackBar(
        "Client deleted",
        "success",
        { vertical: "top", horizontal: "right" },
        3000
      );
    },
    onError: async ({ error }) => {
      CustomLogger.error(error);
      if(isAxiosError(error)){
        if(error?.status === 403){
          showSnackBar(
            "Not Authorized",
            "error",
            { vertical: "top", horizontal: "right" },
            3000
          );
        }else{
          showSnackBar(
            "Error creating Client",
            "error",
            { vertical: "top", horizontal: "right" },
            3000
          );
        }
      }
      showSnackBar(
        "Error creating Client",
        "error",
        { vertical: "top", horizontal: "right" },
        3000
      );
    },
  });

  const clientUpdate = useMutation<
    ApiResponse<Client>,
    ApiError<ValidationErrors>,
    ClientUpdate
  >({
    mutationFn: (data) =>
      GenericApi.put<Client>(`/${deleteRoute}/${data.id}`, data),
    onSuccess: async ({ data }) => {
      CustomLogger.log(data);
      queryClient.invalidateQueries({ queryKey: ["ClientQueryKey"] });
      queryClient.invalidateQueries({ queryKey: [InvoiceQueryKey] })
      showSnackBar(
        "Client updated",
        "success",
        { vertical: "top", horizontal: "right" },
        3000
      );
    },
    onError: async ({ error }) => {
      CustomLogger.error(error);
      if(isAxiosError(error)){
        if(error?.status === 403){
          showSnackBar(
            "Not Authorized",
            "error",
            { vertical: "top", horizontal: "right" },
            3000
          );
        }else{
          showSnackBar(
            "Error creating Client",
            "error",
            { vertical: "top", horizontal: "right" },
            3000
          );
        }
      }
      showSnackBar(
        "Error updating Client",
        "error",
        { vertical: "top", horizontal: "right" },
        3000
      );
    },
  });

  const handleEdit = (id: number) => {
    const client = clientData?.data?.find((client) => client.client.id === id);
    if (client) {
      setClient({
        name: client.name,
        country: client.client.country,
        address: client.client.address,
        vat_number: client.client.vat_number,
        email: client.client.email,
        telephone: client.client.telephone,
        code: client.code,
      });
      setIsEditClient(client.client.id);
    }
  };

  const handleDelete = (id: number) => {
    clientDelete.mutate({ id });
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (client.name.length <= 0) {
      showSnackBar(
        "Enter a Company Name",
        "error",
        { vertical: "top", horizontal: "right" },
        3000
      );
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(client.email)) {
      showSnackBar(
        "Enter a valid email address",
        "error",
        { vertical: "top", horizontal: "right" },
        3000
      );
      return;
    }

    if (client.country.length <= 0) {
      showSnackBar(
        "Enter a Country",
        "error",
        { vertical: "top", horizontal: "right" },
        3000
      );
      return;
    }

    if (client.telephone.length <= 3) {
      showSnackBar(
        "Enter a valid telephone number",
        "error",
        { vertical: "top", horizontal: "right" },
        3000
      );
      return;
    }

    if (client.address.length <= 3) {
      showSnackBar(
        "Enter a Street Address",
        "error",
        { vertical: "top", horizontal: "right" },
        3000
      );
      return;
    }

    if (client.vat_number.length <= 0) {
      showSnackBar(
        "Enter a valid VAT Number",
        "error",
        { vertical: "top", horizontal: "right" },
        3000
      );
      return;
    }

    if (isEditClient !== -1) {
      clientUpdate.mutate({
        ...client,
        id: isEditClient,
        company_id: selectedCompany?.id,
      });
      setClient({
        name: "",
        country: "",
        address: "",
        vat_number: "",
        email: "",
        telephone: "",
        code: "",
      });
      setIsEditClient(-1);
      return;
    }

    addClientMutation.mutate({
      ...client,
      company_id: selectedCompany?.id,
      code: client.code,
    });

    console.log({
      ...client,
      company_id: selectedCompany?.id,
    });
  };

  const sortCallback = (a: {code: string}, b: {code: string})   => {
    const firstDigitA = parseInt(a.code[0]);
    const firstDigitB = parseInt(b.code[0]);
    
    if (firstDigitA !== firstDigitB) {
        return firstDigitA - firstDigitB;
    }

    return a.code.length - b.code.length;
}


  const renderClient = () => {
    return clientData?.data?.sort(sortCallback).map((client) => (
      <tr key={client.client.id}>
        <td className="px-6 py-3 text-center">{client.code}</td>
        <td className="px-6 py-3 text-center">{client.client.name}</td>
        <td className="px-6 py-3 text-center">{client.client.country}</td>
        <td className="px-6 py-3 text-center">{client.client.address}</td>
        <td className="px-4 py-3 text-center">
          <button
            onClick={() => handleEdit(client.client.id)}
            className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
          >
            Edit
          </button>
          <span className="mx-2">|</span>
          <button
            onClick={() => handleDelete(client.client.id)}
            className="font-medium text-red-600 dark:text-red-500 hover:underline"
          >
            Delete
          </button>
        </td>
      </tr>
    ));
  };

  return (
    <section className="mt-24">
      <div className="ml-20 mb-16">
        <h1 className="mb-8 text-xl font-bold text-gray-900 dark:text-white border-b-2 border-[#E7E7E7]">
          Client
        </h1>
        <form onSubmit={onSubmit}>
          <div className="mb-4">
            <div className="flex gap-4 mb-2 items-center">
              <label htmlFor="company_name" className="w-[20%] dark:text-white">
                Company Name
              </label>
              <input
                required
                type="text"
                name="company_name"
                value={client.name}
                onChange={(e) => handleInputChange("name", e.target.value)}
                placeholder="Company Name"
                className="border rounded p-2 dark:bg-[#374151] dark:text-white"
              />
            </div>
            <div className="flex gap-4 mb-2 items-center">
              <label htmlFor="company_name" className="w-[20%] dark:text-white">
                Email
              </label>
              <input
                required
                type="text"
                name="email"
                value={client.email}
                onChange={(e) => handleInputChange("email", e.target.value)}
                placeholder="Email"
                className="border rounded p-2 dark:bg-[#374151] dark:text-white"
              />
            </div>
            <div className="flex gap-4 mb-2 items-center">
              <label htmlFor="country" className="w-[20%] dark:text-white">
                Country
              </label>
              <input
                required
                type="text"
                name="country"
                value={client.country}
                onChange={(e) => handleInputChange("country", e.target.value)}
                placeholder="Country"
                className="border rounded p-2 dark:bg-[#374151] dark:text-white"
              />
            </div>
            <div className="flex gap-4 mb-2 items-center">
              <label htmlFor="country" className="w-[20%] dark:text-white">
                Telephone
              </label>
              <input
                required
                type="text"
                name="telephone"
                value={client.telephone}
                onChange={(e) => handleInputChange("telephone", e.target.value)}
                placeholder="Telephone"
                className="border rounded p-2 dark:bg-[#374151] dark:text-white"
              />
            </div>
            <div className="flex gap-4 mb-2 items-center">
              <label htmlFor="street" className="w-[20%] dark:text-white">
                Street
              </label>
              <input
                required
                type="text"
                name="street"
                value={client.address}
                onChange={(e) => handleInputChange("address", e.target.value)}
                placeholder="Street"
                className="border rounded p-2 dark:bg-[#374151] dark:text-white"
              />
            </div>
            <div className="flex gap-4 mb-5 items-center">
              <label htmlFor="street" className="w-[20%] dark:text-white">
                Vat Number
              </label>
              <input
                required
                type="text"
                name="vat_number"
                value={client.vat_number}
                onChange={(e) =>
                  handleInputChange("vat_number", e.target.value)
                }
                placeholder="Vat Number"
                className="border rounded p-2 dark:bg-[#374151] dark:text-white"
              />
            </div>
            <div className="flex gap-4 mb-5 items-center">
              <label htmlFor="street" className="w-[20%] dark:text-white">
                Code
              </label>
              <input
                required
                type="text"
                name="code"
                value={client.code}
                onChange={(e) => handleInputChange("code", e.target.value)}
                placeholder="Code"
                className="border rounded p-2 dark:bg-[#374151] dark:text-white"
              />
            </div>
          </div>
          <div className="flex gap-5 mt-3">
            <button
              type="submit"
              className="text-white bg-blue-500 hover:bg-blue-700 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center"
            >
              {isEditClient !== -1 ? "Edit Client" : "Create Client"}
            </button>
          </div>
        </form>
      </div>

      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th className="px-4 py-3 text-center">Code</th>
            <th className="px-4 py-3 text-center">Company Name</th>
            <th className="px-4 py-3 text-center">Country</th>
            <th className="px-4 py-3 text-center">Street</th>
            <th className="px-4 py-3 text-center">Actions</th>
          </tr>
        </thead>
        <tbody>{renderClient()}</tbody>
      </table>
    </section>
  );
};

export default ClientForm;
